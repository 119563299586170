import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import AvatarEditor from 'react-avatar-editor'
import './style.css';
import { HeightAnimator } from 'animate-height';
import AuthPopUp from '../UserAuth/AuthPopUp';
import UserPopUp from '../UserAuth/UserPopUp';
import FeedbackPopUp from '../Feedback/FeedbackPopUp';
import IntroPopUp from '../IntroPopUp/IntroPopUp';
import { Auth, Hub } from 'aws-amplify';

function CreateGroupButton() {
    const navigate = useNavigate();

    function handleClick() {
        navigate('create-group')
    }

    return (
        <button className="homeButton" onClick={handleClick}>
            <p>Create Group</p>
        </button>
    );
}


function useOutsideClick(ref, callback) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, callback]);
}



function JoinGroupSection(props) {
    const [showGoToGroupBox, setShowGoToGroupBox] = useState(false);
    const [groupID, setGroupID] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const groupSectionRef = useRef(null);


    useOutsideClick(groupSectionRef, () => {
        setShowGoToGroupBox(false);
    });

    function handleClick() {
        setShowGoToGroupBox(true);
    }

    function handleGroupIDChange(event) {
        const inputText = event.target.value.toUpperCase(); // Convert input to uppercase
        setGroupID(inputText);

        // // Check if input length is between 4 and 8 characters
        // if (inputText.length >= 4 && inputText.length <= 8) {
        //     setErrorMessage(''); // Clear error message if input length is valid
        // } else {
        //     setErrorMessage('Group ID must be between 4 and 8 characters.');
        // }
    }

    

    function handleJoin() {
        navigate('/group/' + groupID);
    }

    return (
        <>
            <button className="homeButton" onClick={handleClick}>
                <p>Join Group</p>
            </button>
            {showGoToGroupBox && (
                <div className="goToGroupBox" ref={groupSectionRef}>
                    <div className="goToGroupBox-content">
                        <div className="goToGroupBox-content-bottom">
                            <input
                                style={{ width: '57%', marginBottom: '0px' }}
                                type="text"
                                placeholder="Group ID"
                                onChange={handleGroupIDChange}
                                value={groupID} // Set value to controlled input
                            />
                            <button
                                style={{
                                    width: '30%',
                                    marginBottom: '0px',
                                    backgroundColor: groupID.length >= 4 && groupID.length <= 6 ? '' : 'gray',
                                    cursor: groupID.length >= 4 && groupID.length <= 6 ? 'pointer' : 'not-allowed',
                                }}
                                onClick={handleJoin}
                                disabled={groupID.length < 4 || groupID.length > 6} // Disable button based on input length
                            >
                                GO
                            </button>
                        </div>
                        {errorMessage !== '' && (
                            <div className="errorMessage">
                                <h3>{errorMessage}</h3>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
}



function Home({updateLocalStorage, user}) {
    const location = useLocation();
    const isRootUrl = location.pathname === '/';
    const [showAuthPopUp, setShowAuthPopUp] = useState(false); 
    const [showUserPopUp , setShowUserPopUp] = useState(false);
    const [showFeedBackPopUp, setShowFeedBackPopUp] = useState(false);
    const [showIntroPopUp, setShowIntroPopUp] = useState(false);
    
    

    return (
        <div className={`home ${isRootUrl ? 'whiteBackground' : ''}`}>  
            
            <img className="blur" 
                src={require('../assets/blurs/blur1.png')}
                style={{
                    position: "absolute", 
                    bottom: "0%", 
                    left: "0%",
                    zIndex: "0",
                    height: "99%",
                    width: "auto",
                    maxWidth: "100%"
                }}>
            </img>
            <img className="blur"
                src={require('../assets/blurs/blur2.png')}
                style={{
                    position: "absolute",
                    bottom: "0",
                    right: "-6px",
                    zIndex: "0",
                    height: "99%",
                    width: "auto",
                    maxWidth: "100%"
                    }}>
            </img>


            <div className='homeContent'>
                
                    <img className='mainLogo' src={require('../assets/logoWithText.png')}/>

                <div style={{textAlign: "center", width: "95%"}}>
                    <h2 style={{margin: "0", marginBottom: "6px"}}>Restaurant Bill Splitting</h2>
                    <img src={require('../assets/help.png')} style={{width: "30px", height: "auto", marginBottom: "6px"}} onClick={() => setShowIntroPopUp(true)}></img>
                </div>
                <div style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
                <div className="homeButtonDiv">
                    <CreateGroupButton />
                </div>
                <div className="homeButtonDiv">
                    <JoinGroupSection updateLocalStorage={updateLocalStorage}/>
                </div>
                <div className="homeButtonDivBottom">
                    {user ? (
                        <button className='homeButton2' onClick={() => setShowUserPopUp(true)}><p className='bottomRowText'>Account</p></button>
                    ) : (
                        <button className='homeButton2' onClick={() => setShowAuthPopUp(true)}><p className='bottomRowText'>Sign In</p></button>
                    )}
                    <button className='homeButton2' onClick={() => setShowFeedBackPopUp(true)}><p className='bottomRowText'>Feedback</p></button>
                </div>
                </div>
                {/* <div className="bottomText">
                    {user ? (
                        <p onClick={() => setShowUserPopUp(true)}>Signed in as :  {user.attributes.name.split(" ")[0]}</p>
                    ) : (
                        <button className='authButton' onClick={() => setShowAuthPopUp(true)}>Sign In/Sign Up</button>
                    )}
                </div> */}
            </div>

            {showAuthPopUp &&
                <AuthPopUp setShow={setShowAuthPopUp}/>
            }
            {showUserPopUp &&
                <UserPopUp setShow={setShowUserPopUp} userIn={user}/>
            }
            {showFeedBackPopUp &&
                <FeedbackPopUp setShow={setShowFeedBackPopUp}/>
            }
            {showIntroPopUp &&
                <IntroPopUp setShow={setShowIntroPopUp}/>
            }   
        </div>
    );
}

export default Home;
