import React, { useState, useEffect } from 'react';
import '../style.css';
import AuthPopUp from '../../UserAuth/AuthPopUp';
import { Auth } from 'aws-amplify';

function SetIdentityChoice({ setShowAuthPopUp, handleNext, setContAsGuest }) {







    return (
        <div className="identityPageContainer">

            
                <div className='identityPageIn'>
                    <button className='identityButton' onClick={() => setShowAuthPopUp(true)}>Log in</button>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'spaceBetween', width: '60%', margin: "5%" }}>
                        {/* <hr style={{ width: '40%', borderColor: 'grey', borderWidth: '1px' }} />
                        <p>OR</p>
                        <hr style={{ width: '40%', borderColor: 'grey', borderWidth: '1px' }} /> */}
                    </div>
                    <button className='identityButtonTransperent' onClick={() => {handleNext(); setContAsGuest(true)}}>Continue as guest</button>

                </div>
           
        </div>
    );
}

export default SetIdentityChoice;
