import React, { useState, useEffect } from 'react';
import '../style.css';
import AuthPopUp from '../../UserAuth/AuthPopUp';
import { Auth } from 'aws-amplify';

function SetIdentityConfirm({  user, setFullName, fullName, imageData, handleNext, handleBack, isContinuingAsGuest, setIsContinuingAsGuest  }) {
    const [isTakingSelfPic, setIsTakingSelfPic] = useState(false);
    const [nameFieldClicked, setNameFieldClicked] = useState(false);
    

    const handleLogOut = async () => {
        if (window.confirm('Are you sure you want to log out?')) {
            try {
                await Auth.signOut();
                setFullName("");
            } catch (error) {
                console.error('Error signing out: ', error);
            }
        }
    }

    return (
        <div className="identityPageContainer">

           
                    
                    <div className='identityPageIn'>
                    <div className="joinGroupBox-content-top">
                        <div style={{ width: "20%" }}></div>
                        <div className="user-picture-circle" style={{ backgroundImage: `url(${imageData})`, width: "55%", display: "flex", justifyContent: "center", alignContent: "center" }}>
                            {!imageData && fullName.length >= 2 && fullName.split(' ').map((name) => name[0]).slice(0, 2).join('').toUpperCase()}
                        </div>
                        <div style={{ width: "20%", display: "flex", justifyContent: "center", alignContent: "center" }}>
                            {/* <button  onClick={() => setIsTakingSelfPic(true)} className='takeUserImageButton'>
                                <img style={{width: "30px", height: "auto"}} src={require('../../assets/camera.png')} alt="Take a picture" />
                            </button>                                          */}
                        </div>
                    </div>
                    <div className="joinGroupBox-content-middle">
                        {isContinuingAsGuest ? (
                            <input style={{ width: "100%", textAlign: "center", height: "58px", fontSize: "28px" }} onChange={(event) => setFullName(event.target.value)} type="text" placeholder="Full Name" value={fullName} />
                        ) : (
                            <>
                                <input style={{ width: "100%", textAlign: "center", height: "58px", fontSize: "28px" }} onClick={() => setNameFieldClicked(true)} type="text" placeholder="Name" value={fullName} disabled />
                                {nameFieldClicked && <p>Log out to change name</p>}
                            </>
                        )}

                    </div>
                    <button className={`identityButtonBottom ${(!fullName) ? 'disabled' : ''}`} disabled={!fullName} onClick={handleNext}>Continue</button>
                    </div>

                    {/* {isContinuingAsGuest ? (
                        <div style={{display: "flex", justifyContent: "flex-start", width: "80%"}}>
                            <button style={{marginBottom: "20px"}} className='identityButtonBottoBack' onClick={() => handleBack()}>Go Back</button>  
                        </div>
                    ) : (
                        <div style={{display: "flex", justifyContent: "flex-start", width: "80%"}}>
                            <button style={{marginBottom: "20px", width: "40%"}} className='identityButtonBottoBack' onClick={() => { handleBack(); handleLogOut(); }}>Log Out</button>
                        </div>
                    )} */}

                
            
        </div>
    );
}

export default SetIdentityConfirm;
