import '../style.css';

function ExtraTotalInfo(props) {
    const { serviceCharge, excludedTax, discount, discountPercentage } = props;
    const listInfo = [];
  
    if (discount > 0) {
      listInfo.push(discountPercentage + "% Discount : -" + discount);
    }
    if (serviceCharge > 0) {
      listInfo.push("Service Charge : " + serviceCharge);
    }
    if (excludedTax > 0) {
      listInfo.push("Tax : " + excludedTax);
    }

  
    //Return list of info as a series of <p  className='serviceChargeAndTaxTesxt'> elements
    return (
      <>
        {listInfo.map((info, index) => (
          <p className='extraTotalInfo' key={index}>
            {info}
          </p>
        ))}
      </>
    );
  }

export default ExtraTotalInfo;