import React, { useState, useRef, useEffect } from 'react';
import MoonLoader from "react-spinners/MoonLoader";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";



export default function BillImageCropper({ img, setBillImageFunction, setBillItemsFunction, currentItemsList, setIsTakingBillPic, setTaxAmount, setServiceChargeAmount }) {
    const [newBillImage, setnewBillImage] = useState(null)

    const [provisionalBillItems, setProvisionalBillItems] = useState([]);
    const [showError, setShowError] = useState(false);
    const [showMissingItemsText, setShowMissingItemsText] = useState(false);
    const [itemName, setItemName] = useState("");
    const [itemPrice, setItemPrice] = useState("");
    const [counter, setCounter] = useState(1);
    const [showAdditem, setShowAddItem] = useState(false);
    const [waiting, setWaiting] = useState(false);
    const addItemSectionRef = useRef(null);


    const [serviceCharge, setServiceCharge] = useState(0);
    const [isEditingServiceCharge, setIsEditingServiceCharge] = useState(false);

    const [tax, setTax] = useState(0);
    const [isEditingTax, setIsEditingTax] = useState(false);


    useEffect(() => {
        if (newBillImage != null) {
            setBillImageFunction(newBillImage)
            setShowError(false)
            setnewBillImage(null)
        }

    }, [newBillImage, setnewBillImage, setShowError, setBillImageFunction]);

    useEffect(() => {
        if ((serviceCharge == "") && !isEditingServiceCharge) {
            setServiceCharge("0.00");
        }

    }, [serviceCharge, isEditingServiceCharge]);

    useEffect(() => {
        if ((tax == "") && !isEditingTax) {
            setTax("0.00");
        }

    }, [tax, isEditingTax]);

    function addItems(itemName, itemPrice, counter) {
        if (itemName == "") {
            return;
        } else if (itemPrice == "") {
            return;
        } else if (counter == "") {
            counter = 1;
        }


        const newItems = [...provisionalBillItems];

        newItems.push([itemName, counter, itemPrice]);
        setItemName("");
        setItemPrice("");
        setCounter(1);
        setProvisionalBillItems(newItems);

    };

    const handleBack = () => {
        setShowError(false);
        setBillImageFunction(null);
    }

    const handleCancel = () => {
        if (window.confirm("Are you sure you want to exit? All progress will be lost.")) {
            setShowError(false);
            setBillImageFunction(null);
        }
    }

    const handleItemChange = (index, key, value) => {
        const newItems = [...provisionalBillItems];
        const selectedItem = newItems[index];
        selectedItem[key] = value;
        setProvisionalBillItems(newItems);
    }

    const handleEditItem = (index) => {
        const newItems = [...provisionalBillItems];
        const selectedItem = newItems[index];
        newItems[index] = {
            ...selectedItem,
            editMode: !selectedItem.editMode,
        };
        setProvisionalBillItems(newItems);
    }

    const handleShowAddItems = () => {
        setShowAddItem(true);
        setTimeout(() => {
            if (addItemSectionRef.current) {
                addItemSectionRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        }, 300); // waits for 300 milliseconds before executing the scroll
    };


    const handleDeleteItem = (index) => {
        const confirmed = window.confirm("Are you sure you want to delete this item?");
        if (confirmed) {
            const newItems = [...provisionalBillItems];
            if (newItems.length === 1) {
                alert('You cannot delete the last item');
                return;
            }
            newItems.splice(index, 1);
            setProvisionalBillItems(newItems);
        }
    }

    const handleAddItems = () => {
        //console.log(provisionalBillItems);
        const newItemsList = [...currentItemsList];
        provisionalBillItems.forEach(item => {
            if (item[1] < 2) {
                newItemsList.push({ name: item[0], price: item[2] });
            } else {
                for (let i = 0; i < item[1]; i++) {
                    newItemsList.push({ name: item[0], price: (item[2] / item[1]) });
                }
            }
        });
        setBillItemsFunction(newItemsList);
        setProvisionalBillItems([]);
        setBillImageFunction(null);
    }

    function getIsValidItemList() {
        for (let i = 0; i < provisionalBillItems.length; i++) {
            if (isNaN(parseFloat(provisionalBillItems[i][2]))) {
                return false;
            }
        }
        return true;
    }

    function getTotal() {
        let total = 0;
        for (let i = 0; i < provisionalBillItems.length; i++) {
            total += parseFloat(provisionalBillItems[i][2]);
        }
        return total;
    }


    const handleUse = async () => {
        //Make API call sending Image to the backend
        //Backend will return the bill items and their price

        const eventBody = {
            imageData: img,
        };

        setWaiting(true);

        try {
            const response = await Promise.race([
                fetch(process.env.REACT_APP_API_URL + "/Prod/scanBill/", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(eventBody)
                }),
                new Promise((_, reject) =>
                    setTimeout(() => reject(new Error('Timeout')), 40000)
                )
            ]);

            if (response.status !== 200) {
                setShowError(true)
                //console.log('Error');
                return;
            }

            const success = await response.json();
            //console.log(success);

            setWaiting(false);

            if (success.itemList.length == 0) {
                setShowError(true);
            }

            console.log(success.extraInfo)
            setServiceCharge(success.extraInfo.serviceCharge)
            setTax(success.extraInfo.tax)



            //Parse Float the price of each item
            let itemList = [];

            success.itemList.forEach(item => {
                try {
                    const price = parseFloat(item[2]);
                    if (!isNaN(price)) {
                        itemList.push([item[0], item[1], price]);
                    } else {
                        //console.log("Error")
                        itemList.push([item[0], item[1], item[2]]);
                    }
                } catch (error) {
                    //console.log("Error")
                    itemList.push([item[0], item[1], item[2]]);
                }
            });

            //console.log(itemList)

            //Add the bill items to the provisionalBillItems array
            setProvisionalBillItems(itemList);
        } catch (err) {
            //console.log(err);
            setWaiting(false);
            setShowError(true);
        }
    };



    return (

        <div className="billImageCropper">
            <img className="blur"
                src={require('../../assets/blurs/blur7.png')}
                style={{
                    position: "absolute",
                    bottom: "0%",
                    right: "0%",
                    zIndex: "-1",
                    height: "99%",
                    width: "auto",
                    maxWidth: "100%"
                }}>
            </img>



            {!waiting && provisionalBillItems.length == 0 && !showError && (
                <>
                    <div className="billImageCropperTitle">
                        <h1 style={{ marginBottom: "0px", marginTop: "5px" }}>Review</h1>
                        <p style={{ marginBottom: "10px", marginTop: "0px" }}>
                            Please review the image, make sure the recipet is straight and text is clear.
                        </p>
                    </div>
                    <div className="billImageCropperImg">
                        <img style={{ maxWidth: '100%', maxHeight: '98%', borderRadius: "25px" }} src={img} />
                    </div>
                    <div className="billImageCropperButtons">
                        <button style={{ width: "30%" }} onClick={handleBack} >Back</button>
                        <button style={{ width: "30%" }} onClick={handleUse}>Use</button>
                    </div>
                </>
            )}

            {waiting && provisionalBillItems.length == 0 && !showError && (
                <>
                    <div className="billImageCropperTitle">
                        <h1 style={{ marginBottom: "0px", marginTop: "5px" }}>Processing</h1>

                    </div>
                    <div className="billImageCropperImg">
                        <MoonLoader />
                        <p style={{ marginBottom: "10px", marginTop: "0px", textAlign: "center" }}>
                            Scanning Image, this may take a minute please wait...
                        </p>
                    </div>
                    <div className="billImageCropperButtons">

                    </div>
                </>
            )}

            {provisionalBillItems.length > 0 && !showError && (
                <>
                    <div className="billImageCropperTitle">
                        <div style={{ display: "flex", width: "100%", flexDirection: "row" }}>
                            <div style={{ display: "flex", width: "20%", justifyContent: "center", alignItems: "center" }}>

                            </div>
                            <div style={{ display: "flex", width: "60%", justifyContent: "center", alignItems: "center" }}>
                                <h1 style={{ marginBottom: "0px", marginTop: "5px" }}>Review</h1>
                            </div>
                            <div style={{ display: "flex", width: "20%", justifyContent: "flex-end", alignItems: "center" }}>
                                <button onClick={handleCancel} className='closeButton'>
                                    <img className='closeButtonImage' src={require('../../assets/close.png')} />
                                </button>
                            </div>

                        </div>


                        <p style={{ marginBottom: "0px", marginTop: "0px" }}>
                            Check if all is correct
                        </p>
                    </div>
                    <div className="provisionalBillItemsContent">
                        <ul style={{ height: "100%", listStyle: 'none' }}>
                            {provisionalBillItems.map((item, index) => (
                                <li key={index} style={{ width: "100%" }}>
                                    <div style={{ backgroundColor: isNaN(item[2]) ? "lightcoral" : (parseFloat((item[2] / item[1])) < 1 ? "#fffeab" : "inherit"), borderRadius: (isNaN(item[2]) || parseFloat((item[2] / item[1])) < 2) ? "5px" : "0" }} className='provisionalBillItem'>

                                        {item.editMode ? (
                                            <div className='provisionalBillItemLeft'>
                                                <div className='nameAndTotalPrice'>
                                                    <input className='billImageCropperEditItemName' value={item[0]} onChange={(e) => handleItemChange(index, 0, e.target.value)} />
                                                    <p className='nameAndTotalPrice2'> x </p>
                                                    <input className='billImageCropperEditItemQuantity' type="number" min="0" max="30" step="1" inputMode='numeric' value={item[1]} onChange={(e) => handleItemChange(index, 1, e.target.value)} />
                                                </div>
                                                <div className='provisionalBillItemQuantity'>
                                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                        <h4>Single Price </h4><h3>{parseFloat((item[2] / item[1])).toFixed(2)}</h3>
                                                    </div>
                                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                        <h4>Total Price </h4><input className='billImageCropperEditItemTotalPrice' type='number' inputMode="decimal" pattern="\d*(\.\d*)?" value={item[2]} onChange={(e) => handleItemChange(index, 2, e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className='provisionalBillItemLeft'>
                                                <div className='nameAndTotalPrice'>
                                                    <h3 className='nameAndTotalPrice1'>{item[0]}</h3>
                                                    <p className='nameAndTotalPrice2'> x </p>
                                                    <h3 className='nameAndTotalPrice3'> {item[1]} </h3>
                                                </div >

                                                <div className='provisionalBillItemQuantity'>
                                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                        <h4>Single Price </h4><h3>{isNaN(item[2]) ? 'N/A' : parseFloat((item[2] / item[1])).toFixed(2)}</h3>
                                                    </div>
                                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                        <h4>Total Price </h4><h3>{isNaN(item[2]) ? 'N/A' : parseFloat(item[2]).toFixed(2)}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        <div className='provisionalBillItemRight'>
                                            {item.editMode ? (
                                                <>
                                                    <button style={{ backgroundColor: "green", display: "flex", justifyContent: "center", alignItems: "center" }} onClick={() => handleEditItem(index)}>
                                                        <img className='provsionalItemsButtons' src={require('../../assets/edit.png')} />
                                                    </button>
                                                    <button style={{ backgroundColor: "red", display: "flex", justifyContent: "center", alignItems: "center" }} onClick={() => handleDeleteItem(index)}>
                                                        <img className='provsionalItemsButtons' src={require('../../assets/bin.png')} />
                                                    </button>
                                                </>
                                            ) : (
                                                <>
                                                    <button style={{ display: "flex", justifyContent: "center", alignItems: "center" }} onClick={() => handleEditItem(index)}>
                                                        <img className='provsionalItemsButtons' src={require('../../assets/edit.png')} />
                                                    </button>
                                                    <button style={{ backgroundColor: "red", display: "flex", justifyContent: "center", alignItems: "center" }} onClick={() => handleDeleteItem(index)}>
                                                        <img className='provsionalItemsButtons' src={require('../../assets/bin.png')} />
                                                    </button>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </li>
                            ))}
                            <li style={{ width: "100%" }} >
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", width: "100%" }}>

                                    {getIsValidItemList() ? (
                                        <>
                                            {showAdditem &&
                                                <div ref={addItemSectionRef} style={{ width: "100%", height: "auto", marginTop: "12px", paddingBottom: "12px", borderBottom: "solid 1px grey" }}>
                                                    <div className='addItemsCreatGroup' style={{ height: "100%" }}>
                                                        <div style={{ margin: "5px", display: "flex", justifyContent: "space-between", width: "100%" }}>
                                                            <input
                                                                type="text"
                                                                placeholder="Item Name"
                                                                style={{ width: "70%", height: "50px" }}
                                                                value={itemName}
                                                                onChange={event => setItemName(event.target.value)}
                                                            />
                                                            <p style={{ marginTop: "auto", marginBottom: "auto" }}>x</p>
                                                            <input
                                                                type="number" min="0" max="30" step="1"
                                                                placeholder="Counter"
                                                                inputMode='numeric'
                                                                value={counter}
                                                                style={{ width: "20%", height: "50px" }}
                                                                onChange={event => setCounter(event.target.value)}
                                                            />
                                                        </div>

                                                        <div style={{ margin: "5px", display: "flex", justifyContent: "space-between", width: "100%" }}>

                                                            <input
                                                                type='number' pattern="\d*(\.\d*)?"
                                                                placeholder="Total Price"
                                                                value={itemPrice}
                                                                inputMode="decimal"
                                                                style={{ width: "50%", height: "50px" }}
                                                                onChange={event =>
                                                                    !isNaN(event.target.value) ? setItemPrice(event.target.value) : null
                                                                }

                                                            />
                                                            <button style={{ width: "45%", height: "50px" }} type="submit" onClick={() => addItems(itemName, itemPrice, counter)} disabled={!itemName || !itemPrice}>Add</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            <div className="extraInfoScan">
                                                <h2>Sub-Total </h2><h2> {getTotal().toFixed(2)}</h2>
                                            </div>
                                            <div className="extraInfoScan">
                                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "70%" }}>
                                                    <h2>Service Charge </h2> {isEditingServiceCharge ? (<input
                                                        style={{ width: "30%" }}
                                                        inputMode="decimal"
                                                        min="0"
                                                        onChange={(event) => setServiceCharge(event.target.value)}
                                                        value={serviceCharge}
                                                        onFocus={(event) => {
                                                            if (event.target.value === '0.00') {
                                                                setServiceCharge('');
                                                            }
                                                        }}
                                                        onBlur={(event) => {
                                                            if (event.target.value === '') {
                                                                setServiceCharge('0.00');
                                                            }
                                                        }}
                                                    />
                                                    ) : (
                                                        <h2> {!isNaN(parseFloat(serviceCharge)) ? parseFloat(serviceCharge).toFixed(2) : serviceCharge}</h2>
                                                    )}
                                                </div>
                                                <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", flexDirection: "row", width: "30%" }}>
                                                    <button style={{ backgroundColor: isEditingServiceCharge ? "green" : "#1A1C33", display: "flex", justifyContent: "center", alignItems: "center", marginRight: "6px" }} onClick={() => setIsEditingServiceCharge(!isEditingServiceCharge)}>
                                                        <img className='provsionalItemsButtons' src={require('../../assets/edit.png')} />
                                                    </button>
                                                    <button style={{ backgroundColor: "red", display: "flex", justifyContent: "center", alignItems: "center" }} onClick={() => setServiceCharge("0.00")}>
                                                        <img className='provsionalItemsButtons' src={require('../../assets/bin.png')} />
                                                    </button>
                                                </div>


                                            </div>
                                            <div className="extraInfoScan">
                                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "70%" }}>
                                                    <h2>Tax</h2>
                                                    {isEditingTax ? (
                                                        <input
                                                            style={{ width: "30%" }}
                                                            type='text'
                                                            inputMode="decimal"
                                                            min="0"
                                                            onChange={(event) => setTax(event.target.value)}
                                                            value={tax}
                                                            onFocus={(event) => {
                                                                if (event.target.value === '0.00') {
                                                                    setTax('');
                                                                }
                                                            }}
                                                            onBlur={(event) => {
                                                                if (event.target.value === '') {
                                                                    setTax('0.00');
                                                                }
                                                            }}
                                                        />
                                                    ) : (
                                                        <h2>{!isNaN(parseFloat(tax)) ? parseFloat(tax).toFixed(2) : tax}</h2>
                                                    )}
                                                </div>
                                                <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", flexDirection: "row", width: "30%" }} >
                                                    <button style={{ backgroundColor: isEditingTax ? "green" : "#1A1C33", display: "flex", justifyContent: "center", alignItems: "center", marginRight: "6px" }} onClick={() => setIsEditingTax(!isEditingTax)}>
                                                        <img className='provsionalItemsButtons' src={require('../../assets/edit.png')} />
                                                    </button>
                                                    <button style={{ backgroundColor: "red", display: "flex", justifyContent: "center", alignItems: "center" }} onClick={() => setTax("0.00")}>
                                                        <img className='provsionalItemsButtons' src={require('../../assets/bin.png')} />
                                                    </button>
                                                </div>

                                            </div>
                                            <div className="extraInfoScan">
                                                <h1>Total </h1><h1> {(getTotal() + (serviceCharge ? parseFloat(serviceCharge) : 0) + (tax ? parseFloat(tax) : 0)).toFixed(2)} </h1>
                                            </div>
                                            <p>You can add tips and discount later</p>

                                        </>
                                    ) : (
                                        <h3>Looks like you have invalid item prices, scroll up and fix them</h3>
                                    )}
                                </div>

                            </li>

                        </ul>
                    </div>
                    <div className="billImageCropperButtons">
                        <button style={{ width: "38%", height: "60%", fontSize: "145%" }} className="addItemsTertirary" onClick={() => handleShowAddItems()}>Add Items</button>
                        <button style={{ width: "32.5%", height: "60%", fontSize: "145%" }} disabled={!getIsValidItemList()} onClick={() => { setTaxAmount(parseFloat(tax).toFixed(2)); setServiceChargeAmount(parseFloat(serviceCharge).toFixed(2)); handleAddItems(); }} className={`addItemsButton ${(!getIsValidItemList()) ? 'disabled' : ''}`}>Confirm</button>
                    </div>
                </>
            )}
            {showError &&
                <>
                    <div className="billImageCropperTitle">
                        <h1 style={{ color: "red" }}>Error</h1>
                    </div>
                    <div className="billImageCropperImg">
                        <h1 style={{ textAlign: "center", color: "red" }}>Oops, no bill detected!</h1>
                        <div className="billImageCropperInstructions">
                            <p>For optimal results</p>
                            <ul style={{ width: "80%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <li>Position the receipt under good lighting.</li>
                                <li>Consider enabling flash if necessary. You can do this by uploading an image from the phones native camera</li>
                                <li>Capture from directly above, keeping your device adn the reciept level.</li>
                            </ul>
                            <p>We appreciate your patience. Kindly note this feature is in its early stages, and we're constantly improving.</p>
                        </div>
                    </div>
                    <div className="billImageCropperButtons">
                        <button style={{ width: "35%" }} onClick={() => { setIsTakingBillPic(true); handleCancel() }}>Try Again</button>
                        <button style={{ width: "35%" }} onClick={handleCancel}>Cancel</button>
                    </div>
                </>

            }

        </div>
    );
}