import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import AuthPopUp from '../UserAuth/AuthPopUp';
import CameraComponent from './SetBillItems/BillImageCamera/BillImageCamera'
import BillImageCropper from "./SetBillItems/BillImageScanner";
import ProgressBar from "@ramonak/react-progress-bar";
import ConfirmCreationBox from './SetBillItems/CreationConfirmation';
import createGroup from './Api/Api';
import CreateGroupOptionsMenu from './OptionsMenu/Options';
import { SetServiceChargeOptionsBox } from './OptionsMenu/Boxes/ServiceChargeBox';
import { SetTaxOptionsBox } from './OptionsMenu/Boxes/TaxBox';
import { Auth } from 'aws-amplify';


import './style.css';


import SetBillItems from './SetBillItems/SetBillItems';
import SetIdentityChoice from './SetIdentity/SetIdentityChoice';
import SetIdentityConfirm from './SetIdentity/SetIdentityConfirm';
import EditSection from './SetBillItems/EditSection';

import SetServiceCharge from './SetServiceCharge/SetServiceCharge';
import SetTax from './SetTax/SetTax';
import { inferTo } from 'react-spring';


function CreateGroup({ updateLocalStorage, user, setHasCreatedGroup }) {
  const location = useLocation();
  const isRootUrl = location.pathname === '/';
  const [showAuthPopUp, setShowAuthPopUp] = useState(false);
  const [optionsMenuOpen, setOptionsMenuOpen] = useState(false);
  const [billImage, setBillImage] = useState(null);
  const [nextButtonDisabled1, setNextButtonDisabled1] = useState(true);
  const [nextButtonDisabled2, setNextButtonDisabled2] = useState(true);
  const [nextButtonDisabled3, setNextButtonDisabled3] = useState(true);

  //Visual State
  const [pendingStep, setPendingStep] = useState(null);
  const [currentStep, setCurrentStep] = useState(user ? 1 : 0);
  const [isTakingBillPic, setIsTakingBillPic] = useState(false);
  const [clickedTaxIncluded, setClickedTaxIncluded] = useState(false);
  const [clickedSCExluded, setClickedSCExluded] = useState(false);
  const [direction, setDirection] = useState('next');
  const [showConfirmCreationBox, setShowConfirmCreationBox] = useState(false);
  const navigate = useNavigate();



  //Group Creation State (All information pushed to backend to creat group)
  const [fullname, setfullname] = useState("");
  const [selfieImage, setSelfieImage] = useState(null);
  const [password, setPassword] = useState("");
  const [serviceCharge, setServiceCharge] = useState(0);
  const [serviceChargeAmount, setServiceChargeAmount] = useState(0);
  const [dontsplitServiceChargeEqually, setDontSplitServiceChargeEqually] = useState(true);
  const [excludedTax, setExludedTax] = useState(0);
  const [excludedTaxAmount, setExludedTaxAmount] = useState(0);
  const [adminAccessEnabled, setAdminAccessEnabled] = useState(false);
  const [tip, setTip] = useState(0);
  const [tipAmount, setTipAmount] = useState(0.00);
  const tipLastChanged = useRef(null);


  const [items, setItems] = useState([]);
  const [isEditingCurrentSelected, setIsEditingCurrentSelected] = useState(false);
  const [editItemName, setEditItemName] = useState("");
  const [editItemPrice, setEditItemPrice] = useState("");

  const [globalDiscount, setGlobalDiscount] = useState(0);

  const [isContinuingAsGuest, setIsContinuingAsGuest] = useState(user ? false : true);



  useEffect(() => {
    if (user) {
      setfullname(user.attributes.name);
      setIsContinuingAsGuest(false);
      setCurrentStep(1);
    } else if (fullname != "") {
      setIsContinuingAsGuest(true);
    }
  }, [user]);



  //Tip Calc
  useEffect(() => {
    if (tipLastChanged.current === 'tip') {
      const billTotal = parseFloat(pureBillTotal());
      const newTipAmount = ((billTotal * tip) / 100).toFixed(2);
      if (newTipAmount !== tipAmount) {
        setTipAmount(newTipAmount);
      }
    }
  }, [tip, items]);

  useEffect(() => {
    if (tipLastChanged.current === 'tipAmount') {
      const billTotal = parseFloat(pureBillTotal());
      if (billTotal > 0) {
        const newTip = ((tipAmount / billTotal) * 100).toFixed(2);
        if (newTip !== tip) {
          setTip(newTip);
        }
      }
    }
  }, [tipAmount, items]);

  // handlers that set the state and the ref
  const handleTipChange = (newTip) => {
    setTip(newTip);
    tipLastChanged.current = 'tip';
  };

  const handleTipAmountChange = (newTipAmount) => {
    setTipAmount(newTipAmount);
    tipLastChanged.current = 'tipAmount';
  };


  //Finish Tip Calc


  useEffect(() => {
    if (user) {

      setfullname(user.attributes.name);
    }
  }, [user]);

  useEffect(() => { //This is done to ensure that diection is set before currentStep is changed. Otherwise tranistion might start before correct direction set
    if (pendingStep !== null) {
      setCurrentStep(pendingStep);
      setPendingStep(null); // reset after changing currentStep
    }
  }, [direction, pendingStep]);

  useEffect(() => {
    if (fullname != "") {
      setNextButtonDisabled1(false);
    } else {
      setNextButtonDisabled1(true);
    }
  }, [fullname]);

  useEffect(() => {
    if (excludedTax > 0 || excludedTaxAmount > 0) {
      setClickedTaxIncluded(false);
    }

    if (clickedTaxIncluded || excludedTax > 0 || excludedTaxAmount > 0) {
      setNextButtonDisabled2(false);
    } else {
      setNextButtonDisabled2(true);
    }
  }, [excludedTax, excludedTaxAmount, clickedTaxIncluded]);

  useEffect(() => {
    if (serviceCharge > 0 || serviceChargeAmount > 0) {
      setClickedSCExluded(false);
    }

    if (clickedSCExluded || serviceCharge > 0 || serviceChargeAmount > 0) {
      setNextButtonDisabled3(false);
    } else {
      setNextButtonDisabled3(true);
    }
  }, [serviceCharge, serviceChargeAmount, clickedSCExluded]);

  function addItems(itemName, itemPrice, counter) {
    if (itemName == "") {
      return;
    } else if (itemPrice == "") {
      return;
    } else if (counter == "") {
      counter = 1;
    }

    const newItems = [...items];
    for (let i = 0; i < counter; i++) {
      newItems.push({ name: itemName, price: itemPrice });
    }
    setItems(newItems);

  };


  function renderPage() {
    return (
      <TransitionGroup className="page-container">
        <CSSTransition key={currentStep} classNames={`page-transition-${direction}`} timeout={300}>
          {getPageComponent()}
        </CSSTransition>
      </TransitionGroup>
    );
  }

  const renderEditSection = () => (
    <EditSection
      addItems={addItems}
      taxAmount={excludedTaxAmount}
      setTaxAmount={setExludedTaxAmount}
      tax={excludedTax}
      setTax={setExludedTax}
      serviceCharge={serviceCharge}
      setserviceCharge={setServiceCharge}
      serviceChargeAmount={serviceChargeAmount}
      setserviceChargeAmount={setServiceChargeAmount}
      discount={globalDiscount}
      setDiscount={setGlobalDiscount}
    />
  );

  function getPageComponent() {
    switch (currentStep) {
      case 0:
        return (
          <SetIdentityChoice
            setShowAuthPopUp={setShowAuthPopUp}
            handleNext={onNext}
            setContAsGuest={setIsContinuingAsGuest}
          />
        );
      case 1:
        return (
          <SetIdentityConfirm
            user={user}
            setFullName={setfullname}
            fullName={fullname}
            imageData={selfieImage}
            handleNext={onNext}
            handleBack={onBack}
            isContinuingAsGuest={isContinuingAsGuest}
            setIsContinuingAsGuest={setIsContinuingAsGuest}
          />
        );
      case 2:
        return (
          <SetBillItems
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            items={items}
            globalDiscount={globalDiscount}
            serviceCharge={serviceCharge}
            excludedTax={excludedTax}
            serviceChargeAmount={serviceChargeAmount}
            excludedTaxAmount={excludedTaxAmount}
            dontsplitServiceChargeEqually={dontsplitServiceChargeEqually}
            setIsTakingBillPic={setIsTakingBillPic}
            setItems={setItems}
            setShowConfirmCreationBox={setShowConfirmCreationBox}
            renderEditSection={renderEditSection}
            tipAmount={tipAmount}
            tip={tip}
            setTipAmount={handleTipAmountChange}
            setTip={handleTipChange}
          />
        );
      default:
        return null;
    }
  }



  function pureBillTotal() {
    let total = 0;
    items.forEach(item => {
      total += parseFloat(item.price);
    });
    return total.toFixed(2);
  }

  function getServiceCharge() {
    if (serviceChargeAmount > 0) return serviceChargeAmount;
    return ((pureBillTotal() * (1 - (globalDiscount / 100))) * (serviceCharge / 100)).toFixed(2);
  }

  function getExcludedTax() {
    if (excludedTaxAmount > 0) return excludedTaxAmount;
    return ((pureBillTotal() * (1 - (globalDiscount / 100))) * (excludedTax / 100)).toFixed(2);
  }

  function getTipAmount() {
    var amount = ((pureBillTotal() * (1 - (globalDiscount / 100))) * (tip / 100)).toFixed(2);
    return isNaN(amount) ? 0 : amount;
  }


  function getDiscountAmount() {
    return (pureBillTotal() * (globalDiscount / 100)).toFixed(2);
  }



  function billTotal(serviceCharge, excludedTax, globalDiscount, items) {
    let total = pureBillTotal();
    total = total - parseFloat(getDiscountAmount()) + parseFloat(getServiceCharge()) + parseFloat(getExcludedTax()) + parseFloat(getTipAmount());
    return total.toFixed(2);
  }

  function onNext() {
    if (currentStep < 3) {
      setDirection('next');
      setPendingStep(currentStep + 1); // update pendingStep instead of currentStep
    }
  }

  function onBack() {
    if (currentStep > 0) {
      setDirection('back');
      setPendingStep(currentStep - 1); // update pendingStep instead of currentStep
    }else{
      navigate("/")
    }
  }

  const toggleAdminAccess = () => {
    setAdminAccessEnabled(!adminAccessEnabled);
  }






  async function handleCreate() {
    const serviceChargeFinal = serviceChargeAmount > 0 ? serviceChargeAmount / pureBillTotal() * 100 : serviceCharge
    const taxFinal = excludedTaxAmount > 0 ? excludedTaxAmount / pureBillTotal() * 100 : excludedTax
    const splitSCEq = !dontsplitServiceChargeEqually
    const roomID = await createGroup(password, fullname, items, selfieImage, serviceChargeFinal, splitSCEq, taxFinal, globalDiscount, updateLocalStorage, tip);
    if (roomID != null && roomID != "") {
      setHasCreatedGroup(true);
      navigate('/group/' + roomID);

    } else {
      alert("Error, couldn't create group, something went wrong")
    }

  }



  return (
    <>
      {/* <img className="blur"
        src={require('../assets/blurs/blur6.png')}
        style={{
          position: "absolute",
          bottom: "0%",
          left: "0%",
          zIndex: "0",
          height: "99%",
          width: "auto",
          maxWidth: "100%"
        }}>
      </img> */}
      <img className="blur"
        src={require('../assets/blurs/blur3.png')}
        style={{
          position: "absolute",
          bottom: "0",
          right: "-6px",
          zIndex: "0",
          height: "99%",
          width: "auto",
          maxWidth: "100%"
        }}>
      </img>

      <div className='create-group-container'>
        {showAuthPopUp && <AuthPopUp setShow={setShowAuthPopUp} />}
        <div className='header' style={{alignItems: "center"}}>

          <div style={{ width: "15%", display: "flex", justifyContent: "center", alignContent: "center", zIndex: "99" }}>
            <button className='backButton' onClick={() => onBack()} style={{ backgroundColor: "transparent", margin: "0" }}>
              <img className='backArrowImage' src={require('../assets/backArrow.png')} alt="Back" />
            </button>
          </div>
          <div style={{ width: "70%", textAlign: "center" }}>
            <h2>Create Group</h2>
          </div>
          <div style={{ width: "15%" }}>

          </div>



        </div>

        <div className="body">
          {renderPage()}
        </div>
      </div>
      {billImage &&
        <BillImageCropper img={billImage} setBillImageFunction={setBillImage} setBillItemsFunction={setItems} currentItemsList={items} setIsTakingBillPic={setIsTakingBillPic} setTaxAmount={setExludedTaxAmount} setServiceChargeAmount={setServiceChargeAmount} />
      }
      {isTakingBillPic &&
        <CameraComponent setIsTakingBillPic={setIsTakingBillPic} setBillImage={setBillImage} />
      }
      {showConfirmCreationBox &&
        <ConfirmCreationBox createGroup={handleCreate} setConfirmCreationBox={setShowConfirmCreationBox} billTotal={billTotal()} />

      }
      {optionsMenuOpen &&
        <CreateGroupOptionsMenu handleCloseOptions={setOptionsMenuOpen}
          password={password}
          setPassword={setPassword}
          setAdminAccess={toggleAdminAccess}
          adminAccessEnabled={adminAccessEnabled}
          serviceChargeBox={<SetServiceChargeOptionsBox setserviceCharge={setServiceCharge} serviceCharge={serviceCharge} dontsplitServiceChargeEqually={dontsplitServiceChargeEqually} serviceChargeAmount={serviceChargeAmount} setserviceChargeAmount={setServiceChargeAmount} setDontSplitServiceChargeEqually={setDontSplitServiceChargeEqually} setScNo={setClickedSCExluded} step={currentStep} goNext={onNext} />}

          taxBox={<SetTaxOptionsBox setTax={setExludedTax} tax={excludedTax} taxAmount={excludedTaxAmount} setTaxAmount={setExludedTaxAmount} setTaxYes={setClickedTaxIncluded} step={currentStep} goNext={onNext} />}
          discount={globalDiscount}
          setDiscount={setGlobalDiscount}
        />
      }
    </>
  );

}


export default CreateGroup;