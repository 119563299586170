import React, { useState } from 'react';
import { ScaleLoader } from "react-spinners";

import { useAuth } from './auth'; // Import useAuth from your AuthProvider

function SignIn({ setShow }) {
    const { signIn, answerCustomChallenge, confirmSignUp, resendConfirmationEmail  } = useAuth(); // Destructure methods from useAuth
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState(''); // OTP state
    const [errorMessage, setErrorMessage] = useState('');
    const [isWaiting, setIsWaiting] = useState(false);
    const [isOtpRequired, setIsOtpRequired] = useState(false); // State to track if OTP is required
    const [isUnconfirmed, setIsUnconfirmed] = useState(false);
    const [confirmationCode, setConfirmationCode] = useState('');

    const handleSignIn = async () => {
        try {
            setIsWaiting(true);
            await signIn(email); // Use the signIn method from useAuth
            setIsOtpRequired(true); // Prompt user for OTP
            setIsWaiting(false);
        } catch (error) {
            setErrorMessage(error.message);
            console.error('Error signing in', error);
            setIsWaiting(false);
        }
    };

    const handleStartConfirmSignUp = async () => {
        try {
            setIsUnconfirmed(true)
            setIsWaiting(true);
            await resendConfirmationEmail(email);
            setIsWaiting(false);
        } catch (error) {
            setErrorMessage(error.message);
            setIsWaiting(false);
        }
    };

    const handleConfirmSignUp = async () => {
        try {
            setIsWaiting(true);
            await confirmSignUp(email, confirmationCode);
            setIsWaiting(false);
            setErrorMessage('')
            setIsUnconfirmed(false); 
            
        } catch (error) {
            setErrorMessage(error.message);
            setIsWaiting(false);
        }
    };

    const handleOtpSubmit = async () => {
        try {
            setIsWaiting(true);
            const isAuthenticated = await answerCustomChallenge(otp); // Use the answerCustomChallenge method from useAuth
            if (isAuthenticated) {
                await alert('Successfully signed in!');
                setShow(false);
            } else {
                setErrorMessage('Invalid OTP. Please try again.');
            }
            setIsWaiting(false);
        } catch (error) {
            setErrorMessage(error.message);
            console.error('Error submitting OTP', error);
            setIsWaiting(false);
        }
    };

    return (
        <div className="inputs">
            {isOtpRequired ? (
                <>
                    <h2>Enter Code in Email</h2>
                    <input
                        value={otp}
                        onChange={e => setOtp(e.target.value)}
                        placeholder="One Time Password"
                    />
                    <button 
                        onClick={handleOtpSubmit} 
                        disabled={otp.length === 0 || isWaiting}>
                        {isWaiting ? <ScaleLoader color="white" height="18px"/> : 'Submit'}
                    </button>
                    {errorMessage && <p style={{color: 'red'}}>{errorMessage}</p>}
                </>
            ) : isUnconfirmed ? (
                <>
                    <h3 style={{textAlign: 'center'}}>Enter the confirmation code sent to your email</h3>
                    <p style={{ fontFamily: 'monospace', fontWeight: 'bold', fontSize: '1.1rem', color: '#333', marginTop: '0' }}>{email}</p>
                    <p style={{margin: '0', color: 'lightgray'}}>Cant find it? Check Junk</p>
                    <input
                        value={confirmationCode}
                        onChange={e => setConfirmationCode(e.target.value)}
                        placeholder="Confirmation Code"
                    />
                    <button onClick={handleConfirmSignUp} disabled={confirmationCode.length === 0 || isWaiting}>
                        {isWaiting ? <ScaleLoader color="white" height="18px"/> : 'Confirm'}
                    </button>
                </>
            ) : (
                <>
                    {/* Commenting out third-party auth for now
                    <button className="identity-button">Sign In With Google</button>
                    <button className="identity-button">Sign In With Facebook</button>
                    <button className="identity-button">Sign In With Apple</button>
                    */}
                    <input
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        placeholder="Email"
                    />
                    <button 
                        onClick={handleSignIn} 
                        disabled={email.length === 0 || isWaiting}>
                        {isWaiting ? <ScaleLoader color="white" height="18px"/> : 'Send Code To Email'}
                    </button>
                    { errorMessage == 'User is not confirmed.' ?
                        (
                            <div style={{display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center'}}>
                                <p style={{color: 'red'}}>Please confrim your email first</p>
                                <button
                                    onClick={handleStartConfirmSignUp}
                                >
                                    Confirm
                                </button>
                            </div>
                        )
                        :
                        (
                            <>
                            {errorMessage && <p style={{color: 'red'}}>{errorMessage}</p>}
                            </>
                        )

                    }
                    
                </>
            )}
        </div>
    );
}

export default SignIn;