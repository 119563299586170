import React, { useState, useEffect } from 'react';
import AnimateHeight from "react-animate-height";
import '../style.css';

export default function ListItem({ item, index, selectedIndex, isEditingCurrentSelected, handleRemoveItem, handleEdit, setEditItemName, setEditItemPrice, handleExpandItem, handleSubmitEdit, editItemName, editItemPrice, serviceCharge, splitSCEqually, excludedTax, discount }) {

    const [height, setHeight] = useState("auto");

    useEffect(() => {
        const element = document.getElementById(`item${index}`);
        const clone = element.cloneNode(true); // create a clone of the element
        clone.style.height = "auto"; // set the clone's height to auto
        clone.style.position = "absolute"; // remove the clone from the flow of the document
        clone.style.visibility = "hidden"; // hide the clone
        element.parentNode.appendChild(clone); // add the clone to the DOM
        const newHeight = clone.clientHeight + 3; // get the clone's height
        element.parentNode.removeChild(clone); // remove the clone from the DOM
        setHeight(newHeight);
    }, [selectedIndex, index]);

    function ExtraInfo(props) {
        const infoList = [];
      
        if (discount > 0) {
          infoList.push(["- Discount", `- ${parseFloat(props.item.price * (discount / 100)).toFixed(2)}`]);
        }
        if (serviceCharge > 0  && !splitSCEqually) {
          infoList.push(["+ Service Charge", `+ ${parseFloat((props.item.price * (1 - discount / 100)) * (serviceCharge / 100)).toFixed(2)}`]);
        }
        if (excludedTax > 0) {
          infoList.push(["+ Tax", `+ ${parseFloat((props.item.price * (1 - discount / 100)) * (excludedTax / 100)).toFixed(2)}`]);
        }
      
        return (
          <>
            {infoList.map((info, index) => (
              <div style={{ display: "flex", justifyContent: "space-between" }} key={index}>
                <p className="servicechargeAndTaxBillItem">{info[0]}</p>
                <p className="servicechargeAndTaxBillItem">{info[1]}</p>
              </div>
            ))}
            {infoList.length > 0 && (
              <div style={{ display: "flex", justifyContent: "space-between", marginTop: "8px" }}>
                <p style={{ fontWeight: "bold", fontSize: "17px" }} className="servicechargeAndTaxBillItem">
                  Final Price
                </p>
                <p style={{ fontWeight: "bold", fontSize: "17px" }} className="servicechargeAndTaxBillItem">
                  
                  {parseFloat(
                    (props.item.price * (1 - discount / 100)) * (1 + serviceCharge / 100) +
                      (props.item.price * (1 - discount / 100)) * (excludedTax / 100)
                  ).toFixed(2)}
                </p>
              </div>
            )}
          </>
        );
    }

    return (
        <li key={index} className='item-Display-Group' >
          <AnimateHeight className='animatedItemDiv' id={"item" + index} duration={500} height={height}>
            <div >
                {selectedIndex === index && isEditingCurrentSelected ? (
                    <>
                    {/* Edditing Item Display */}
                        <div style={{display: "flex", justifyContent: "space-between", marginTop: "14px"}}>
                            <input className='item-name-edit' type="text" defaultValue={item.name} onChange={event => setEditItemName(event.target.value)} />
                            <input className='item-price-edit' inputMode='decimal' type='number' pattern="\d*(\.\d*)?" defaultValue={item.price} onChange={event => !isNaN(event.target.value) ? setEditItemPrice(event.target.value) : null } />
                        </div>
                        <ExtraInfo item={{name: editItemName, price: editItemPrice}} />
                        <div style={{display: "flex", justifyContent: "center"}} >
                            <button style={{width: "60%", height: "40px", margin: "9px"}} className="item-button-submit" onClick={() => handleSubmitEdit(index)} disabled={!editItemName}>
                                Finish
                            </button>
                        </div>
                    </>
                ) : (
                    <>
                    {/* Normal Item Display */}
                        <div onClick={() => handleExpandItem(index)} style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <h3>{item.name}</h3>
                            <h3> {parseFloat(item.price).toFixed(2)}</h3>
                        </div>
                        {selectedIndex === index && (
                            <>
                           
                            <ExtraInfo item={item} />
                           
                            
                            <div style={{display: "flex", justifyContent: "space-between"}}>
                                <button className="item-button" onClick={() => handleRemoveItem(index)}>
                                    Delete
                                </button>
                                <button className="item-button" onClick={() => handleEdit(index)}>
                                    Edit
                                </button>
                            </div>
                            </>
                        )}
                    </>
                )}
            </div>
          </AnimateHeight>
    </li>
    );
};