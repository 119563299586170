import React, { useState, useEffect, useCallback, useMemo } from 'react';
import AnimateHeight from "react-animate-height";
import './style.css';
import ScaleLoader from "react-spinners/ScaleLoader";

import { ClaimItem, UnClaimItem } from '../ApiCalls/Api';




export default function ListItem({ roomID, item, fetchFunc, users, userName, ClaimerIcon, serviceCharge, splitSCEqually, excludedTax, discount, index, selectedIndex, setSelectedIndex, setIsWaiting, tip, currentItemList }) {

  const [isWaitingClaim, setIsWaitingClaim] = useState(false);
  
  const handleClaimItem = async () => {
    if (item.claimees.length > 0) {
      let claimeeString = "";

      if (item.claimees.length === 1) {
        claimeeString = item.claimees[0];
      } else if (item.claimees.length === 2) {
        claimeeString = item.claimees[0] + " and " + item.claimees[1];
      } else {
        for (let i = 0; i < item.claimees.length; i++) {
          if (i === item.claimees.length - 1) {
            claimeeString += "and " + item.claimees[i];
          } else {
            claimeeString += item.claimees[i] + ", ";
          }
        }
      }
      if (window.confirm('This Item is already claimed by ' + claimeeString + ". Click OK if you want to share this item with them.")) {
        setIsWaiting(true);
        setIsWaitingClaim(true);
        await ClaimItem(roomID, item.id);
        setIsWaiting(false);
        setIsWaitingClaim(false);
        await fetchFunc();
        setSelectedIndex(-1)
      }
    } else {
      setIsWaiting(true);
      setIsWaitingClaim(true);
      const claimResponse = await ClaimItem(roomID, item.id, currentItemList);
      if (typeof claimResponse != "string"){ //The only other thing it can be is dict/object#
        if (window.confirm('This Item was also just claimed by ' + claimResponse.newClaimee.S + ". Click OK if you want to share this item with them.")) {
          setIsWaiting(true);
          setIsWaitingClaim(true);
          await ClaimItem(roomID, item.id, "noCheck");
        }
      }
      setIsWaiting(false);
      setIsWaitingClaim(false);
      await fetchFunc();
      setSelectedIndex(-1)
    }
  };

  const handleUnclaimItem = async () => {
    setIsWaiting(true);
    setIsWaitingClaim(true);
    await UnClaimItem(roomID, item.id, currentItemList);
    setIsWaiting(false);
    setIsWaitingClaim(false);
    await fetchFunc();
    setSelectedIndex(-1)
  };


  const isItemClaimed = item.claimees.includes(userName);

  const backgroundColor = () => {
    //console.log(item.claimees.length)
    //No background color if item is not claimed
    if (item.claimees.length === 0) {
      return "transparent";
      // } else if (isItemClaimed && item.claimees.length > 1) {
      //   return "#fffeab";
    } else if (isItemClaimed) {
      return "lightgreen";
    } else {
      return "transparent";
    }
  };

  const [height, setHeight] = useState("auto");

  useEffect(() => {
    const element = document.getElementById(`item${index}`);
    const clone = element.cloneNode(true); // create a clone of the element
    clone.style.height = "auto"; // set the clone's height to auto
    clone.style.position = "absolute"; // remove the clone from the flow of the document
    clone.style.visibility = "hidden"; // hide the clone
    element.parentNode.appendChild(clone); // add the clone to the DOM
    const newHeight = clone.clientHeight + 3; // get the clone's height
    element.parentNode.removeChild(clone); // remove the clone from the DOM
    //console.log("New height: " + newHeight);
    setHeight(newHeight);
  }, [selectedIndex, index, item.claimees.length]);



  function ExtraInfo(props) {
    const infoList = [];

    if (discount > 0) {
      infoList.push(["- Discount", `- ${parseFloat(props.item.price * (discount / 100)).toFixed(2)}`]);
    }
    if (serviceCharge > 0 && !splitSCEqually) {
      infoList.push(["+ Service Charge", `+ ${parseFloat((props.item.price * (1 - discount / 100)) * (serviceCharge / 100)).toFixed(2)}`]);
    }
    if (excludedTax > 0) {
      infoList.push(["+ Tax", `+ ${parseFloat((props.item.price * (1 - discount / 100)) * (excludedTax / 100)).toFixed(2)}`]);
    }
    if (tip > 0) {
      infoList.push([" + Tip", `+ ${parseFloat((props.item.price * (1 - discount / 100)) * (tip / 100)).toFixed(2)}`]);
    }

    return (
      <>
        {infoList.map((info, index) => (
          <div style={{ display: "flex", justifyContent: "space-between" }} key={index}>
            <p className="servicechargeAndTaxBillItem">{info[0]}</p>
            <p className="servicechargeAndTaxBillItem">{info[1]}</p>
          </div>
        ))}
        {infoList.length > 0 && (
          <div style={{ display: "flex", justifyContent: "space-between", marginTop: "8px" }}>
            <p style={{ fontWeight: "bold", fontSize: "20px" }} className="servicechargeAndTaxBillItem">
              Final Price
            </p>
            <p style={{ fontWeight: "bold", fontSize: "21px" }} className="servicechargeAndTaxBillItem">

              {parseFloat(
                (props.item.price * (1 - discount / 100)) * (1 + serviceCharge / 100) +
                (props.item.price * (1 - discount / 100)) * (excludedTax / 100) +
                (props.item.price * (1 - discount / 100)) * (tip / 100)
              ).toFixed(2)}
            </p>
          </div>
        )}
        {item.claimees.length > 1 &&
          <div style={{ display: "flex", justifyContent: "space-between", marginTop: "8px" }}>
            <p style={{ fontWeight: "bold", fontSize: "20px" }} className="servicechargeAndTaxBillItem">
              Shared by {item.claimees.length}
            </p>
            <p style={{ fontWeight: "bold", fontSize: "21px" }} className="servicechargeAndTaxBillItem">

              {parseFloat((
                (props.item.price * (1 - discount / 100)) * (1 + serviceCharge / 100) +
                (props.item.price * (1 - discount / 100)) * (excludedTax / 100) +
                (props.item.price * (1 - discount / 100)) * (tip / 100)
              ) / item.claimees.length).toFixed(2)}
            </p>
          </div>
        }
      </>
    );
  }


  return (
    <li className="item-Display-Group" onClick={() => setSelectedIndex(index)}>
      <div style={{ backgroundColor: backgroundColor() }} className="itemContainer">
        <AnimateHeight className='animatedItemDiv' id={"item" + index} duration={500} height={height}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              whiteSpace: selectedIndex === index ? "normal" : "nowrap"
            }}
            onClick={(event) => {
              event.stopPropagation(); // stop propagation here
              selectedIndex != index ? setSelectedIndex(index) : setSelectedIndex(-1);
            }}
          >
            <div style={{ width: "70%", height: "auto" }}>
              <h3
                className={`billItem-Name ${selectedIndex === index ? "" : "billItem-Name-collapsed"}`} //!TODO: expand text when item is selected
                style={{ width: "100%", overflow: selectedIndex === index ? "visible" : "hidden" }}
              >

                {item.itemName}
              </h3>
            </div >

            <h3 className="billItem-Price"> {parseFloat(item.price).toFixed(2)}</h3>
          </div>
          {selectedIndex != index && item.claimees.length > 0 && (

            <div className="calimeesUnexpanded">
              <p style={{ margin: "0px", width: "30%", marginRight: "10px" }}>
                Claimed by
              </p>
              <ul className="claimeesUL">
                {item.claimees.map((claimee) => (
                  <li className="claimeeIcon" key={claimee}>
                    <ClaimerIcon users={users} claimee={claimee} />
                  </li>
                ))}
              </ul>
            </div>
          )}
          {selectedIndex != index && item.claimees.length === 0 && (
            <p class="unclaimedIndicator">Not Claimed</p>
          )}
          {selectedIndex != index && item.claimees.length > 1 && isItemClaimed && (
            <p class="unclaimedIndicator" style={{ backgroundColor: "#fffeab", color: "black", marginLeft: "3px" }}>Shared</p>
          )}

          {selectedIndex == index && (
            <div >

              {item.claimees.length === 0 ? (
                <>
                  <div>
                    <ExtraInfo item={item} />
                  </div>
                  <p class="unclaimedIndicator">Not Claimed</p>
                </>
              ) : (
                <>
                  <div>
                    <ExtraInfo item={item} />
                  </div>
                  {item.claimees.length > 3 &&
                    <div style={{ display: "flex", justifyContent: "center", marginTop: "14px", marginBottom: "14px" }}>
                      {isItemClaimed ? (

                        <button style={{ width: "225px", display: "flex", justifyContent: 'center', alignItems: "center" }} onClick={handleUnclaimItem} disabled={isWaitingClaim}>{isWaitingClaim ? <ScaleLoader color="white" height="18px" /> : <>Tap to Un-Claim</>}</button>
                      ) : (
                        <button style={{ width: "225px", display: "flex", justifyContent: 'center', alignItems: "center" }} onClick={handleClaimItem} disabled={isWaitingClaim}>{isWaitingClaim ? <ScaleLoader color="white" height="18px" /> : <>Tap to Claim</>}</button>
                      )}
                    </div>
                  }
                  <div className="calimeesExpanded">
                    <p style={{ margin: "0px", width: "120px", marginRight: "10px", paddingTop: "13px" }}>
                      Claimed by
                    </p>
                    <ul className="claimeesULExpanded">
                      {item.claimees.map((claimee) => (
                        <li className="claimeeIconExpanded" key={claimee}>
                          {
                            claimee == userName ? (
                              <>
                                <ClaimerIcon users={users} claimee={claimee} />
                                <p>You</p>
                              </>
                            ) : (
                              <>
                                <ClaimerIcon users={users} claimee={claimee} />
                                <p>{claimee}</p>
                              </>
                            )}
                        </li>
                      ))}
                    </ul>

                  </div>
                </>
              )}
              <div style={{ display: "flex", justifyContent: "center", marginTop: "12px" }}>
                {isItemClaimed ? (

                  <button style={{ width: "225px", display: "flex", justifyContent: 'center', alignItems: "center" }} onClick={handleUnclaimItem} disabled={isWaitingClaim}>{isWaitingClaim ? <ScaleLoader color="white" height="18px" /> : <>Tap to Un-Claim</>}</button>
                ) : (
                  <button style={{ width: "225px", display: "flex", justifyContent: 'center', alignItems: "center" }} onClick={handleClaimItem} disabled={isWaitingClaim}>{isWaitingClaim ? <ScaleLoader color="white" height="18px" /> : <>Tap to Claim</>}</button>
                )}
              </div>

            </div>
          )}
        </AnimateHeight>
      </div>
    </li>
  );
};