import React, { useRef } from 'react';
import Webcam from 'react-webcam';
import './style.css';
import FadeLoader from "react-spinners/FadeLoader";

export default function CameraComponent({setIsTakingBillPic, setBillImage}) {
  const webcamRef = useRef(null);

  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    //console.log(imageSrc);
    setBillImage(imageSrc);
    setIsTakingBillPic(false);
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setBillImage(reader.result);
        setIsTakingBillPic(false);
      };
      reader.readAsDataURL(file);
    }
  }

  return (
    <>
    
    <div className="scanner_cam_container">
        <p style={{color: "white", textAlign: "center", zIndex: "100", position: "absolute", top: "2%", left: "0", right: "0", margin: "auto", width: "220px"}} className="unconfirmedIndicator">Keep recipet straight and flat</p>
        {/* <p style={{color: "white", textAlign: "center", marginRight: "auto", marginLeft: "auto", zIndex: "100", left: "0", right: "0" , position: "absolute", width: "307px", top: "83%", opacity: "75%"}} className="unconfirmedIndicator">For flash tap "Upload" to use native camera</p> */}
      <FadeLoader color="white" style={{position: "absolute", top: "50%", left: "50%"}}/>
      <Webcam
        className="scanner_cam"
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        forceScreenshotSourceSize={true}
        videoConstraints={{
          width: 1920,
          height: 1080, 
          facingMode: 'environment',
        }}
      />
      <div className="scanner_cam_overlay"></div>
      <div className="scanner_cam_overlay_bottom">
        <button className="scanner_cam_button" onClick={() => {setIsTakingBillPic(false)}}>Cancel</button>
        <button className="scanner_cam_button_cap" onClick={capture}></button>
        <input
          style={{ display: 'none' }}
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          id="upload-button-file"
        />

        <button className="scanner_cam_button" onClick={() => document.getElementById("upload-button-file").click()}>Photos</button>
        
      </div>
    </div>
    </>
  );
};

