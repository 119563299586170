import { useNavigate, useLocation } from 'react-router-dom';

export default async function createGroup(password, fullname, items, imageData, serviceCharge, splitSCEq, excludedTax, globalDiscount, updateLocalStorage, tip) { //Create Room


    //console.log({ fullname, password, items });
    const itemsFormatted = []
    for (let i = 0; i < items.length; i++) {
        //console.log("   " + items[i]['name'] + ": " + items[i]['price']);
        itemsFormatted.push({ itemName: items[i]['name'], itemPrice: items[i]['price'], claimees: [], itemID: "placeHolder" });
    }

    //console.log(itemsFormatted);

    const getIP = async () => {
        return fetch("https://api.ipify.org?format=json")
            .then((response) => response.json())
            .then((data) => data.ip);
    };

    const hostIP = await getIP();
    //console.log("IP : " + hostIP);

    const imageDataFinal = imageData == null ? "" : imageData

    const eventBody = {
        password: password,
        hostIP: hostIP,
        hostUsername: fullname.trim(),
        items: itemsFormatted,
        userImage: imageDataFinal,
        serviceCharge: serviceCharge,
        splitServiceChargeEqually: splitSCEq,
        exludedTax: excludedTax,
        globalDiscount: globalDiscount,
        tip: tip,
    };


    //console.log("Event Body :" + JSON.stringify(eventBody));

    const response = await fetch(process.env.REACT_APP_API_URL + "/Prod/create/", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(eventBody),
        timeout: 60000 // 30 seconds
    });


    if (response.status !== 200) {
        alert("Error : Failed to create group")
        return;
    }
    const success = await response.json();
    //console.log(success);
    //console.log("RoomID " + success.RoomID);
    //console.log("Token " + success.AccessToken);
    //console.log("username " + fullname);

    updateLocalStorage(success.AccessToken, success.RoomID, fullname.trim(), password);

    return success.RoomID;
};