import React, { useState, useEffect, useRef } from 'react';
import './style.css';
import { ScaleLoader } from "react-spinners";

export const SendFeedback = async (category, email, body, picture, setIsWaiting) => {
    // Map our frontend fields to the Lambda expected keys:
    const requestBody = {
        subject: category,
        sender: email,
        body: body,
    };

    // If an image is provided, add it (and optionally a filename) to the payload.
    if (picture) {
        requestBody.image = picture;
        requestBody.filename = "feedback_image.png";
    }

    try {
        setIsWaiting(true)
        const response = await fetch(process.env.REACT_APP_API_URL + "/Prod/sendFeedback", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
        });

        if (response.status === 200) {
            await response.json();
            alert('Feedback sent successfully');
        } else {
            console.error('Error sending feedback:', response);
            alert('Error: Something went wrong');
        }
        setIsWaiting(false)
    } catch (error) {
        console.error('Error triggering Lambda function:', error);
        alert('Error: Something went wrong');
        setIsWaiting(false)
    }
};

export default function FeedbackPopUp({ setShow }) {
    const [visible, setVisible] = useState(false);
    const [category, setCategory] = useState('');
    const [email, setEmail] = useState('');
    const [body, setBody] = useState('');
    const [picture, setPicture] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [isWaiting, setIsWaiting] = useState(false);
    const fileInputRef = useRef(null);

    const placeholderText = {
        "General feedback": "Describe your overall experience or suggestions.",
        "Bug report": "Please describe the issue, what you were trying to do, and any error messages.",
        "Bill scanning": "Describe your issue or feedback about bill scanning, and don't forget to upload an image of the bill."
    };

    useEffect(() => {
        setVisible(true);
        return () => {
            setVisible(false);
        };
    }, []);

    const handleFeedbackSubmit = async (e) => {
        e.preventDefault();
        if (category === 'Bill scanning' && !picture) {
            alert('Please upload an image of the bill for bill scanning feedback.');
            return;
        }
        await SendFeedback(category, email, body, picture, setIsWaiting);
        setShow(false);
    };

    const handlePictureChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPicture(reader.result);
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const clearImage = () => {
        setPicture(null);
        setImagePreview(null);
    };

    const triggerFileInput = () => {
        fileInputRef.current.click();
    };

    return (
        <>
            <div className={`disabledBackground ${visible ? 'greyedOutvisible' : ''}`}></div>
            <div className="FeedbackPopUp">
            <div className='topPopUp' style={{marginTop: "30px", marginBottom: "25px"}}>
                    <div style={{width: "15%"}}>

                    </div>
                    <div style={{width: "70%"}}>
                        <h2 style={{  margin: "0", textAlign: "center" }}>Help Us Improve</h2>
                    </div>
                    <div style={{width: "15%"}}>
                        <button onClick={() => setShow(false)} style={{ width: "25px", height: "25px", margin: "0" }} className='closeButton'>
                            <img src={require('../assets/close.png')} alt="Close" className='closeButtonImage'/>
                        </button>
                    </div>
                    
                    
                </div>
                <form onSubmit={handleFeedbackSubmit}>
                    <div>
                        <select value={category} onChange={(e) => setCategory(e.target.value)} required>
                            <option value="" disabled>Please Select</option>
                            <option value="General feedback">General Feedback</option>
                            <option value="Bug report">Bug Report</option>
                            <option value="Bill scanning">Bill Scanning</option>
                        </select>
                    </div>
                    <div>
                        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Your email" required />
                    </div>
                    <div>
                        <textarea value={body} onChange={(e) => setBody(e.target.value)} placeholder={placeholderText[category] || "Select a category to see instructions."} required />
                    </div>
                    <div style={{ display: 'none' }}>
                        <input type="file" ref={fileInputRef} onChange={handlePictureChange} accept="image/*" />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: "15px", alignItems: "start" }}>
                        {imagePreview ? (
                            <>
                                <img src={imagePreview} alt="Selected" style={{ width: '48%', height: 'auto', maxHeight: "135px", border: "2px solid black", borderRadius: "20px" }} />
                                <button type="button" onClick={clearImage} style={{ width: '48%', margin: "0", height: "auto" }}>
                                    <img src={require('../assets/bin.png')} alt="Remove" style={{ width: '40px', height: '40px' }} />
                                </button>
                            </>
                        ) : (
                            <button type="button" onClick={triggerFileInput} style={{ padding: '15px', width: '100%', margin: '0' }}>Upload Image</button>
                        )}
                    </div>
                    <button type="submit" style={{ padding: '15px', width: '100%' }}>
                        {isWaiting ? <ScaleLoader color="white" height="18px"/> : 'Send Feedback'}
                    </button>
                </form>
            </div>
        </>
    );
}
