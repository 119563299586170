import { useParams } from 'react-router-dom';
import BeatLoader from "react-spinners/BeatLoader";
import React, { useState, useEffect, useCallback, useRef } from 'react';
import MoonLoader from "react-spinners/MoonLoader";

import GroupOptionsMenu from './Options/Options';
import ShareGroupBox from './ShareGroup/ShareGroup';
import ListItem from './ItemLists/ListItem';
import ClaimerIcon from './ClaimerIcon/ClaimerIcon';
import JoinGroupBox from './JoinGroup/JoinGroup';
import YourTotalDetails from './YourTotalDetails/YourTotalDetails';
import ConfirmSelection from './ConfirmPage/ConfirmSelection';
import SplitComplete from './SplitComplete/SplitComplete';
import { QueryBackend, JoinGroup, ClaimItem, UnClaimItem } from './ApiCalls/Api';
import BirthdayNotification from './BirthdayNotification/BirthdayNotification';
import ShareConfirmation from './ShareConfirmation/ShareConfirmation';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Confetti from 'react-confetti'


let webSocket = null;

export const sendMessageThroughWebSocket = (message) => {

    if (webSocket && webSocket.readyState === WebSocket.OPEN) {
        webSocket.send(JSON.stringify(message));
    } else {
        console.error("WebSocket is not open.");
        // Handle the case where WebSocket is not ready
    }
};




function BillItems({ updateLocalStorage, hasCreatedGroup, setHasCreatedGroup, showOptions, setShowOptions, showShareGroup, setShowShareGroup, userNotInRoom, setUserNotInRoom, birthdayUsers, setBirthdayUsers, disableConffeti, setDisableConfetti, showBirthdayNotification, setShowBirthdayNotification }) {
    const [items, setItems] = useState([]);
    const [users, setUsers] = useState([]);
    const [serviceCharge, setServiceCharge] = useState(0);
    const [splitSCEqually, setSplitSCEqually] = useState(true);
    const [excludedTax, setExcludedTax] = useState(0);
    const [globalDiscount, setGlobalDiscount] = useState(0);
    const [allUsersConfirmed, setAllUsersConfirmed] = useState(false);
    const [userConfirmed, setUserConfirmed] = useState(false);
    const [tip, setTip] = useState(0);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [isWaiting, setIsWaiting] = useState(false);
    const [showTotalDetails, setShowTotalDetails] = useState(false);
    const [hasJoined, setHasJoined] = useState(hasCreatedGroup ? true : false);
    const [wasBirthDayUserLastRefresh, setWasBirthDayUserLastRefresh] = useState(false);
    const userName = localStorage.getItem("username");

    const itemsRef = useRef(items);


    function purebillTotal() {
        let total = 0;
        items.forEach(item => {
            total += parseFloat(item.price);
        });
        return total.toFixed(2);
    }

    function getServiceCharge(purebillTotal) {
        return ((purebillTotal * (1 - (globalDiscount / 100))) * (serviceCharge / 100)).toFixed(2);
    }

    function getExcludedTax(purebillTotal) {
        return ((purebillTotal * (1 - (globalDiscount / 100))) * (excludedTax / 100)).toFixed(2);
    }

    function getDiscountAmount(purebillTotal) {
        return (purebillTotal * (globalDiscount / 100)).toFixed(2);
    }

    function getTipAmount(purebillTotal) {
        return ((purebillTotal * (1 - (globalDiscount / 100))) * (tip / 100)).toFixed(2);

    }


    function birthdayUsersClaimTotal(targetUser) {
        let total = 0;
        items.forEach(item => {
            if (item.claimees.includes(targetUser)) {
                total += (parseFloat(item.price) / item.claimees.length);
            }
        });
        total = total - (total * (globalDiscount / 100));
        //
        let serviceChargeD = (total * (serviceCharge / 100));
        if (splitSCEqually) {
            serviceChargeD = getServiceCharge(purebillTotal()) / (Object.keys(users).length);
        }
        const excludedTaxD = (total * (excludedTax / 100));
        //apply excluded tax, apply service charge
        total = total + serviceChargeD + excludedTaxD;


        return parseFloat(total).toFixed(2);
    }


    function getAmountPayingForBirthdayUsers() {
        let total = 0;

        birthdayUsers.forEach(bdayUser => {

            total = total + birthdayUsersClaimTotal(bdayUser);
        });


        total = total / (Object.keys(users).length - birthdayUsers.length)

        return total.toFixed(2);
    }



    const claimedTotal = (targetUser) => {
        let total = 0;
        items.forEach(item => {
            if (item.claimees.includes(targetUser)) {
                total += (parseFloat(item.price) / item.claimees.length);
            }
        });
        total = total - (total * (globalDiscount / 100));

        let serviceChargeD = (total * (serviceCharge / 100));
        if (splitSCEqually) {
            serviceChargeD = getServiceCharge(purebillTotal()) / (Object.keys(users).length);
        }
        const excludedTaxD = (total * (excludedTax / 100));

        total = total + serviceChargeD + excludedTaxD;

        if (!birthdayUsers.includes(targetUser)) {
            total = parseFloat(total) + parseFloat(getAmountPayingForBirthdayUsers());
        }

        return parseFloat(total).toFixed(2);
    };



    function billTotal() {
        let total = purebillTotal();
        total = total - parseFloat(getDiscountAmount(purebillTotal())) + parseFloat(getServiceCharge(purebillTotal())) + parseFloat(getExcludedTax(purebillTotal())) + parseFloat(getTipAmount(purebillTotal()));

        return total.toFixed(2);
    }




    function UnclaimedTotal(items) {
        let total = 0;
        items.forEach(item => {
            if (item.claimees.length === 0) {
                total += parseFloat(item.price);
            }
        });
        const unclaimedItems = items.filter(item => item.claimees.length === 0);
        //apply global discount
        total = total - (total * (globalDiscount / 100));
        //
        const serviceChargeD = (total * (serviceCharge / 100));
        const excludedTaxD = (total * (excludedTax / 100));
        const tipAmount = (total * (tip / 100));
        //apply excluded tax, apply service charge
        total = total + serviceChargeD + excludedTaxD + tipAmount;



        return total.toFixed(2);
    }

    //The following section is to dynmically set height of bill items section ---------------------------------|

    const billContentSectionRef = useRef(null);
    const titleSectionRef = useRef(null);
    const infoSectionRef = useRef(null);
    const [mainSectionHeight, setMainSectionHeight] = useState('80%');

    useEffect(() => {
        const infoHeight = infoSectionRef.current ? infoSectionRef.current.offsetHeight : 0;

        if (infoHeight == 90) {

            const billContentHeight = billContentSectionRef.current ? billContentSectionRef.current.offsetHeight : 0;
            const titleHeight = titleSectionRef.current ? titleSectionRef.current.offsetHeight : 0;

            // Assuming your root div has a fixed height, you can calculate the remaining percentage
            const totalHeight = titleHeight + infoHeight; // This will be in pixels
            const remainingHeight = billContentHeight - totalHeight;

            setMainSectionHeight(`${remainingHeight}px`);
        }
    }, []);



    //End section ---------------------------------------------------------------------------------------------|

    //The following section is Show if user hass been kicked  -------------------------------------------------|


    useEffect(() => {

        if (!userNotInRoom) {
            setHasJoined(true)
            return;
        }

        if (userNotInRoom && hasJoined) {
            alert("Uh oh, looks like an admin has kicked you from the group... awkward")
            setHasJoined(false);
        }
    }, [userNotInRoom]);

    //End section ---------------------------------------------------------------------------------------------|



    let { id } = useParams();




    const fetchData = useCallback(async () => {
        setIsWaiting(true);
        const info = await QueryBackend(id, setUserNotInRoom);

        if (Object.keys(info).length === 0) { // Check if the info object is empty
            setIsWaiting(false);
            setUserNotInRoom(true);
            return; // If empty, exit the fetchData function


        }

        //console.log(info);

        //console.log(userName);
        // console.log(info.users[userName][3]);

        setUsers(info.users);
        setItems(info.billItems);
        setBirthdayUsers(info.birthdayUsers);
        setServiceCharge(info.serviceCharge);
        setSplitSCEqually(info.dontsplitServiceChargeEqually);
        setExcludedTax(info.excludedTax);
        setGlobalDiscount(info.globalDiscount);
        setAllUsersConfirmed(info.allUsersConfirmed);
        setTip(info.tip);


        try {
            setUserConfirmed(info.users[userName][3]);
        } catch {
            setUserConfirmed(info.users[localStorage.getItem("username")][3]);
        }


        setIsWaiting(false);



    }, [items, id, users, setItems, setUsers]);

    //WEBSOCKet ----------------------------------------



    useEffect(() => {
        itemsRef.current = items;
    }, [items]);


    const [hasConnected, setHasConnected] = useState(false);
    const [isWebSocketClosed, setIsWebSocketClosed] = useState(false);
    const [connectionAttempts, setConnectionAttempts] = useState(0);
    const maxConnectionAttempts = 3;
    const reconnectDelay = 5000;
    
    const initializeWebSocket = () => {
        if (!hasJoined) return; // Ensure we have the prerequisite to establish a connection

        const accessToken = localStorage.getItem("AccessToken");
        const webSocketURL = `wss://b03cy7eaa7.execute-api.eu-west-2.amazonaws.com/Prod/?roomID=${id}&accessToken=${accessToken}&userName=${userName}`;
        webSocket = new WebSocket(webSocketURL);

        webSocket.onopen = () => {
            console.log("WebSocket connection established.");
            setHasConnected(true);
            setConnectionAttempts(0); // Reset connection attempts on successful connection
        };


        webSocket.onmessage = (event) => {
            try {
                // Parsing the JSON string in the message
                const messageObject = JSON.parse(event.data);

                // If your message has a 'body' field 
                if (messageObject.body) {
                    messageObject.body = JSON.parse(messageObject.body);
                    //console.log("Body:")
                    //console.log(messageObject.body)

                    if (messageObject.body.Message && messageObject.body.Message == "User successfully authenticated and read data") {
                        const info = messageObject.body.Information
                        const currentItems = itemsRef.current;

                        console.log(info);

                        const updatedItems = info.billItems;
                        console.log(items.length);
                        console.log(updatedItems.length);

                        function capitalizeFirstLetter(string) {
                            return string.charAt(0).toUpperCase() + string.slice(1);
                        }

                        if (currentItems.length == updatedItems.length) {
                            currentItems.forEach((item, i) => {
                                if (item.claimees.includes(userName) && item.id == updatedItems[i].id) {
                                    if (updatedItems[i].claimees.length > item.claimees.length) {
                                        console.log("-----Item " + item.itemName + " has claimees " + item.claimees.join())
                                        console.log("-----Updated Item " + updatedItems[i].itemName + " has claimees " + updatedItems[i].claimees.join())
                                        const updatedItem = updatedItems[i]

                                        // Find new claimee in updatedItem
                                        const newClaimees = updatedItem.claimees.filter(claimee => !item.claimees.includes(claimee));

                                        newClaimees.forEach(newClaimee => {

                                            toast.warn(capitalizeFirstLetter(newClaimee) + ' has also claimed your ' + item.itemName.trim() + '. You are now sharing this item with them.', {
                                                position: "top-center",
                                                autoClose: 12000,
                                                hideProgressBar: false,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: true,
                                                progress: undefined,
                                            });
                                        });
                                    } else if (updatedItems[i].claimees.length < item.claimees.length) {
                                        console.log("-----Item " + item.itemName + " has claimees " + item.claimees.join())
                                        console.log("-----Updated Item " + updatedItems[i].itemName + " has claimees " + updatedItems[i].claimees.join())
                                        const updatedItem = updatedItems[i]

                                        // Find old claimee in item
                                        const oldClaimees = item.claimees.filter(claimee => !updatedItem.claimees.includes(claimee));

                                        oldClaimees.forEach(oldClaimee => {

                                            toast.warn(capitalizeFirstLetter(oldClaimee) + ' is no longer sharing ' + item.itemName.trim() + ' with you. Your total may have increased.', {
                                                position: "top-center",
                                                autoClose: 12000,
                                                hideProgressBar: false,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: true,
                                                progress: undefined,
                                            });
                                        });
                                    }

                                }
                            });
                        }

                        //Update room
                        setUsers(info.users);
                        setItems(info.billItems);
                        setBirthdayUsers(info.birthdayUsers);
                        setServiceCharge(info.serviceCharge);
                        setSplitSCEqually(info.dontsplitServiceChargeEqually);
                        setExcludedTax(info.excludedTax);
                        setGlobalDiscount(info.globalDiscount);
                        setAllUsersConfirmed(info.allUsersConfirmed);
                        setTip(info.tip);


                        try {//
                            setUserConfirmed(info.users[userName][3]);
                        } catch {
                            setUserConfirmed(info.users[localStorage.getItem("username")][3]);
                        }

                    }
                }

                webSocket.onclose = () => {
                    console.log("WebSocket connection closed.");
                    setIsWebSocketClosed(true); // Set state to indicate WebSocket is closed
                };

                } catch (error) {
                    console.error("Error parsing WebSocket message:", error);
                }
            };

            webSocket.onerror = () => {
                console.error("WebSocket error encountered.");
                // Directly attempt to reconnect with a delay, without incrementing here
                attemptReconnect();
            };
        };
        
        const attemptReconnect = () => {
            setConnectionAttempts(prevAttempts => {
                if (prevAttempts < maxConnectionAttempts) {
                    console.log(`Reconnection attempt ${prevAttempts + 1} of ${maxConnectionAttempts}`);
                    setTimeout(() => {
                        initializeWebSocket(); // Attempt to reconnect after a delay
                    }, reconnectDelay);
                    return prevAttempts + 1;
                } else {
                    console.error("Max WebSocket connection attempts reached. Please try again later.");
                    return prevAttempts; // Return the current value to avoid further attempts
                }
            });
        };
        
        useEffect(() => {
            console.log('Attempting to connect to WebSocket');
            initializeWebSocket();
            return () => {
                if (webSocket) {
                    console.log('Closing WebSocket connection.');
                    webSocket.close();
                }
            };
        }, [hasJoined]);

    // useEffect hook for handling reconnection
    useEffect(() => {
        if (isWebSocketClosed && hasConnected) {
            const reconnect = window.confirm("You have been disconnected. Press OK to reconnect.");
            if (reconnect) {
                initializeWebSocket();
            }
            setIsWebSocketClosed(false); // Reset the state after handling disconnection
        }
    }, [isWebSocketClosed, hasConnected]);




    //WEBSOCKet ----------------------------------------

    useEffect(() => {
        if (birthdayUsers.includes(userName) && !wasBirthDayUserLastRefresh) {
            setShowBirthdayNotification(true);
            setWasBirthDayUserLastRefresh(true);
        }

        if (!birthdayUsers.includes(userName)) {
            setShowBirthdayNotification(false);
            setWasBirthDayUserLastRefresh(false);
        }
    }, [birthdayUsers]);

    const [showUnClimedNotif, setShowUnClimedNotif] = useState(false);

    const notifyUnClaim = () => toast.error('Looks like someone unclaimed an item. Check to see if it\'s yours!', {
        position: "top-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    useEffect(() => {
        console.log("restrrt")
        console.log(UnclaimedTotal(items))
        if (showUnClimedNotif && UnclaimedTotal(items) > 0) {
            notifyUnClaim();
            setShowUnClimedNotif(false);
        } else if (showUnClimedNotif && UnclaimedTotal(items) == 0) {
            setShowUnClimedNotif(false);
        }

    }, [showUnClimedNotif]);




    useEffect(() => {


        //console.log(hasCreatedGroup);

        const fetchAndSetData = async () => {
            await fetchData();
            // Set the data after fetching
        }

        // Call fetchAndSetData once when the component mounts
        fetchAndSetData();

        // // Then set up an interval to call it every 5 seconds
        // const intervalId = setInterval(() => {
        //     fetchAndSetData();
        // }, 3000);

        // // Finally, clear the interval when the component unmounts
        // return () => clearInterval(intervalId);
    }, []);

    return (
        <>


            <div className='bill-content' ref={billContentSectionRef}>

                <div className='bill-Items-Title-Section' ref={titleSectionRef}>
                    <div style={{ width: "10%" }}>

                    </div>
                    <div style={{ width: "80%", display: "flex", justifyContent: "center", alignContent: "center" }}>
                        <h1 style={{ margin: "0px" }}>Select Your Items</h1>
                    </div>
                    <div style={{ width: "10%", display: "flex", justifyContent: "right", alignContent: "center" }}>
                        {/* {isWaiting &&
                            <>

                                <MoonLoader color={'#a1a1a1'} size={20} />
                            </>
                        } */}
                    </div>

                </div>
                <div className='bill-Items-Content-Section' style={{ height: mainSectionHeight }}>
                    <div style={{ overflow: 'scroll', height: '100%' }}>
                        <ul style={{ listStyle: 'none' }}>
                            {items.map((item, index) => (
                                <ListItem
                                    roomID={id}
                                    key={item.id}
                                    item={item}
                                    fetchFunc={fetchData}
                                    users={users}
                                    userName={userName}
                                    ClaimerIcon={ClaimerIcon}
                                    serviceCharge={serviceCharge}
                                    splitSCEqually={splitSCEqually}
                                    excludedTax={excludedTax}
                                    discount={globalDiscount}
                                    index={index}
                                    selectedIndex={selectedIndex}
                                    setSelectedIndex={setSelectedIndex}
                                    setIsWaiting={setIsWaiting}
                                    tip={tip}
                                    ClaimItem={ClaimItem}
                                    UnClaimItem={UnClaimItem}
                                    currentItemList={items}
                                />
                            ))}
                            <li style={{ width: "80%", display: "flex", justifyContent: 'flex-start', alignItems: "center", flexDirection: "column", paddingTop: "20px" }}>
                                <div style={{ display: "flex", justifyContent: "space-between", width: "90%" }}>
                                    <p className='bill-Items-Information-Text'>Bill Total</p> <p className='bill-Items-Information-Text'>{billTotal(items)}</p>
                                </div>
                                <div style={{ display: "flex", justifyContent: "space-between", width: "89%", height: "25%", marginTop: "3px" }}>
                                    <p className='bill-Items-Information-Text'>Unclaimed Total</p> <p style={{ color: UnclaimedTotal(items) > 0 ? 'red' : 'green' }} className='bill-Items-Information-Text'>{UnclaimedTotal(items)}</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='bill-Items-Information-Section' ref={infoSectionRef}>
                    <div className='div-ForLine'></div>
                    {/* <div style={{ display: "flex", justifyContent: "space-between", width: "80%" }}>
                        <p className='bill-Items-Information-Text'>Bill Total : </p> <p className='bill-Items-Information-Text'>{billTotal(items)}</p>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "80%", height: "25%", marginTop: "3px" }}>
                        <p className='bill-Items-Information-Text'>Unclaimed Total</p> <p style={{ color: UnclaimedTotal(items) > 0 ? 'red' : 'green' }} className='bill-Items-Information-Text'>{UnclaimedTotal(items)}</p>
                    </div> */}
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "85%", height: "100%" }}>
                        <button onClick={() => setShowTotalDetails(true)} className="your-total-button">
                            <p className='bill-Items-Your-Total-Information-Text'>Your Total</p>
                            <p className='bill-Items-Your-Total-Information-Text'>{claimedTotal(userName)}</p>
                        </button>
                    </div>

                </div>
                {/* Pop Ups */}


                {showTotalDetails &&
                    <YourTotalDetails userName={userName}
                        items={items}
                        total={claimedTotal(userName)}
                        birthdayUsers={birthdayUsers}
                        totalGift={getAmountPayingForBirthdayUsers()}
                        discount={globalDiscount}
                        serviceCharge={serviceCharge}
                        splitSCEqually={splitSCEqually}
                        excludedTax={excludedTax}
                        users={users}
                        setShowTotalDetails={setShowTotalDetails}
                        serivceChargeTotal={getServiceCharge(purebillTotal())}
                        roomID={id}
                        fetchFunc={fetchData}
                        setIsWaiting={setIsWaiting}
                        tip={tip}
                        UnClaimItem={UnClaimItem}
                    />
                }


            </div>
            {showOptions &&
                <GroupOptionsMenu handleCloseOptions={setShowOptions}
                    roomID={id}
                    users={users}
                    items={items}
                    userName={userName}
                    discount={globalDiscount}
                    serviceCharge={serviceCharge}
                    splitSCEqually={splitSCEqually}
                    tax={excludedTax}
                    birthdayUsers={birthdayUsers}
                    queryBackend={fetchData}
                    total={purebillTotal()}
                    showConfettiOption={birthdayUsers.includes(userName)}
                    disableConffeti={disableConffeti}
                    setDisableConfetti={setDisableConfetti}
                    tip={tip}
                />
            }
            {showShareGroup &&
                <ShareGroupBox handleCloseShareGroup={setShowShareGroup}
                    setHasCreatedGroup={setHasCreatedGroup}
                />
            }
            {(UnclaimedTotal(items) <= 0 && !userNotInRoom) && !allUsersConfirmed && !hasCreatedGroup && hasJoined &&
                <ConfirmSelection userName={userName}
                    items={items}
                    total={claimedTotal(userName)}
                    birthdayUsers={birthdayUsers}
                    totalGift={getAmountPayingForBirthdayUsers()}
                    discount={globalDiscount}
                    serviceCharge={serviceCharge}
                    splitSCEqually={splitSCEqually}
                    excludedTax={excludedTax}
                    users={users}
                    setShowTotalDetails={setShowTotalDetails}
                    serivceChargeTotal={getServiceCharge(purebillTotal())}
                    roomID={id}
                    fetchFunc={fetchData}
                    setIsWaiting={setIsWaiting}
                    userConfirmed={userConfirmed}
                    getTotalFunc={claimedTotal}
                    globalDiscount={globalDiscount}
                    id={id}
                    setShowClosedNotif={setShowUnClimedNotif}
                    tip={tip}
                    ClaimItem={ClaimItem}
                    UnClaimItem={UnClaimItem}
                />
            }
            {allUsersConfirmed && !userNotInRoom &&
                <SplitComplete total={claimedTotal(userName)}
                    isBirthday={birthdayUsers.includes(userName)}
                    users={users}
                    items={items}
                    getTotalFunc={claimedTotal}
                    birthdayUsers={birthdayUsers}
                />
            }
            {userNotInRoom &&
                <JoinGroupBox joinGroup={JoinGroup}
                    updateLocalStorage={updateLocalStorage}
                    fetchFunction={fetchData}
                    setHasJoined={setHasJoined}
                />
            }

        </>
    );
}





function Group({ updateLocalStorage, hasCreatedGroup, setHasCreatedGroup }) {
    let { id } = useParams();
    // roomID = id;
    //console.log("Params")
    //console.log(id)
    //console.log(localStorage.getItem("username"))
    //console.log(localStorage.getItem("AccessToken"))

    const userName = localStorage.getItem("username");
    const [showOptions, setShowOptions] = useState(false);
    const [showShareGroup, setShowShareGroup] = useState(hasCreatedGroup ? true : false);
    const [userNotInRoom, setUserNotInRoom] = useState(hasCreatedGroup ? false : true);

    const [birthdayUsers, setBirthdayUsers] = useState([]);
    const [disableConffeti, setDisableConfetti] = useState(false);
    const [showBirthdayNotification, setShowBirthdayNotification] = useState(false);

    const notifyBday = () => toast('🥳 Happy Birthday! Your freinds are paying for your items!', {
        position: "top-center",
        autoClose: 30000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });

    useEffect(() => {
        console.log("show notif")
        if (showBirthdayNotification) {
            notifyBday();
            setShowBirthdayNotification(false);
        }
    }, [showBirthdayNotification]);

    return (
        <div className="home">
            <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1706712240098107" crossorigin="anonymous"></script>
            <ToastContainer
                position="top-center"
                autoClose={30000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />

            {birthdayUsers.includes(userName) && !disableConffeti &&
                <>
                    <Confetti style={{ zIndex: "1000" }} numberOfPieces={50} />
                </>
            }
            {/* {showBirthdayNotification &&
                <BirthdayNotification style={{zIndex: "1000"}} setShow={setShowBirthdayNotification} />
            } */}

            <div className="group-title-section" style={{}}>
                <div style={{ width: "20%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <button style={{ zIndex: userNotInRoom ? "0" : "10" }} className='backButton' onClick={() => setShowShareGroup(true)}>
                        <img className='backArrowImage' src={require('../assets/share.png')} alt="Back" />
                    </button>
                </div>
                <div style={{ width: "60%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <h1 style={{ color: "white", zIndex: 10 }}>ID : {id}</h1>
                </div>
                <div style={{ width: "20%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <button style={{ zIndex: userNotInRoom ? "0" : "10" }} className='optionsButton' onClick={() => setShowOptions(true)}>
                        <img className='optionsImage' src={require('../assets/options.png')} alt="Options" />
                    </button>
                </div>

            </div>

            <div className="group-main-section">
                <BillItems updateLocalStorage={updateLocalStorage}
                    hasCreatedGroup={hasCreatedGroup}
                    setHasCreatedGroup={setHasCreatedGroup}
                    showOptions={showOptions}
                    setShowOptions={setShowOptions}
                    showShareGroup={showShareGroup}
                    setShowShareGroup={setShowShareGroup}
                    userNotInRoom={userNotInRoom}
                    setUserNotInRoom={setUserNotInRoom}
                    showBirthdayNotification={showBirthdayNotification}
                    setShowBirthdayNotification={setShowBirthdayNotification}
                    disableConffeti={disableConffeti}
                    setDisableConfetti={setDisableConfetti}
                    birthdayUsers={birthdayUsers}
                    setBirthdayUsers={setBirthdayUsers}
                />
            </div>
        </div>
    );
}
export default Group;