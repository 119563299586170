import React, { useState, useEffect, useRef } from 'react';
import './style.css';



export default function IntroPopUp({ setShow }) {
    const [visible, setVisible] = useState(false);


    useEffect(() => {
        setVisible(true);
        return () => {
            setVisible(false);
        };
    }, []);

    return (
        <>
            <div className={`disabledBackground ${visible ? 'greyedOutvisible' : ''}`}></div>
            <div className="FeedbackPopUp">
                <div className='topPopUp' style={{ marginTop: "30px" }}>
                    <div style={{ width: "15%" }}>

                    </div>
                    <div style={{ width: "70%" }}>
                        <h2 style={{ margin: "0", textAlign: "center" }}>How This Works</h2>
                    </div>
                    <div style={{ width: "15%" }}>
                        <button onClick={() => setShow(false)} style={{ width: "25px", height: "25px", margin: "0" }} className='closeButton'>
                            <img src={require('../assets/close.png')} alt="Close" className='closeButtonImage' />
                        </button>
                    </div>


                </div>

                <p style={{ width: "85%", textAlign: "center" }}>DineDivide is for when a bill needs to be split quickly and accurately among multiple people. It works via these steps:</p>
                <div className='homeSteps'>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "start" }}>
                        <p style={{ marginRight: "7px" }}>1.</p>
                        <p>Someone Creates a Group</p>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "start" }}>
                        <p style={{ marginRight: "7px" }}>2.</p>
                        <p>They scan the bill</p>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "start" }}>
                        <p style={{ marginRight: "7px" }}>3.</p>
                        <p>Everyone else joins the group on their own phone (no app download needed)</p>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "start" }}>
                        <p style={{ marginRight: "7px" }}>4.</p>
                        <p>Everyone selects (claims) the items they ordered</p>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "start" }}>
                        <p style={{ marginRight: "7px" }}>5.</p>
                        <p>Each person gets their individual total</p>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "start" }}>
                        <p style={{ marginRight: "7px" }}>6.</p>
                        <p>Everyone confirms their selection</p>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "start" }}>
                        <p style={{ marginRight: "7px" }}>7.</p>
                        <p>Each person pays their share to the waiter</p>
                    </div>

                </div>
            </div>
        </>
    );
}
