import React, { useState } from 'react';

function EditSection({
    addItems,
    taxAmount,
    setTaxAmount,
    tax,
    setTax,
    serviceCharge,
    setserviceCharge,
    serviceChargeAmount,
    setserviceChargeAmount,
    discount,
    setDiscount,
}) {
    const [view, setView] = useState(0); // initial state

    //for new items
    const [itemName, setItemName] = useState("");
    const [itemPrice, setItemPrice] = useState("");
    const [counter, setCounter] = useState(1);
    const [tempDiscount, settempDiscount] = useState(discount);

    const handleDiscountInputChange = (event) => {
        setDiscount(event.target.value);
    };



    let componentToRender;
    switch (view) {
        case 0:
            componentToRender = <div className='fourControlButtons'>
                <button className='billItemControlButtonQuater' type="button" onClick={() => setView(1)}>
                    Add
                </button>
                <button className='billItemControlButtonQuater' type="button" onClick={() => setView(2)}>
                    Tax
                </button>
                <button className='billItemControlButtonQuater' type="button" onClick={() => setView(3)}>
                    Service Charge
                </button>
                <button className='billItemControlButtonQuater' type="button" onClick={() => setView(4)}>
                    Discount
                </button>
            </div>;
            break;
        case 1:
            componentToRender = <div className='addItemsCreatGroup'>
                <div style={{ margin: "5px", width: "100%" }}>
                    <input
                        type="text"
                        placeholder="Item Name"
                        style={{ width: "100%", height: "50px" }}
                        value={itemName}
                        onChange={event => setItemName(event.target.value)}
                    />
                </div>

                <div style={{ margin: "5px", display: "flex", justifyContent: "space-between", width: "100%" }}>

                    <input
                        type='number' pattern="\d*(\.\d*)?"
                        inputMode='decimal'
                        placeholder="Item Price"
                        value={itemPrice}
                        style={{ width: "60%", height: "50px" }}
                        onChange={event =>
                            !isNaN(event.target.value) ? setItemPrice(event.target.value) : null
                        }

                    />
                    <p style={{ marginTop: "auto", marginBottom: "auto" }}>x</p>
                    <input
                        type="number" min="0" max="30" step="1"
                        placeholder="Counter"
                        inputMode='decimal'
                        value={counter}
                        style={{ width: "20%", height: "50px" }}
                        onChange={event => setCounter(event.target.value)}
                    />
                </div>
                <div style={{ margin: "5px", width: "100%", justifyContent: "space-between", display: "flex", alignItems: "center", flexDirection: "row" }}>
                    <button style={{ width: "45%", height: "50px" }} type="submit" onClick={() => setView(0)} >Cancel</button>
                    <button style={{ width: "45%", height: "50px" }} type="submit" onClick={() => addItems(itemName, itemPrice, counter)} disabled={!itemName || !itemPrice}>Add</button>

                </div>
            </div>;
            break;
        case 2:
            componentToRender = <div className='tascInputsContainer'>
                <div className='tascInputContainer'>
                    <div className='tascInputFeild'>
                        <h2 style={{ margin: "0%", marginBottom: "10px", textAlign: "left" }}>Tax Percentage   </h2>
                        <input type='number' pattern="\d*(\.\d*)?" min="0" max="100"
                            style={{ width: "27%", height: "50px", fontSize: "160%", textAlign: "right", backgroundColor: (taxAmount != "" && taxAmount != "0") ? 'grey' : 'white', transition: 'background-color 0.5s ease' }}
                            value={tax}
                            inputMode='decimal'
                            onChange={event => setTax(event.target.value)}
                            disabled={(taxAmount != "" && taxAmount != "0")}
                        />
                    </div>

                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'spaceBetween', width: '60%' }}>
                    <hr style={{ width: '40%', borderColor: 'grey', borderWidth: '1px', borderTop: "none" }} />
                    <p>OR</p>
                    <hr style={{ width: '40%', borderColor: 'grey', borderWidth: '1px', borderTop: "none" }} />
                </div>
                <div className='tascInputContainer'>
                    <div className='tascInputFeild'>
                        <h2 style={{ margin: "0%", marginBottom: "10px", textAlign: "left" }}>Tax Amount   </h2>
                        <input type='number' pattern="\d*(\.\d*)?" min="0"
                            style={{ width: "27%", height: "50px", fontSize: "160%", textAlign: "right", backgroundColor: (tax != "" && tax != "0") ? 'grey' : 'white', transition: 'background-color 0.5s ease' }}
                            value={taxAmount}
                            inputMode='decimal'
                            onChange={event => setTaxAmount(event.target.value)}
                            disabled={(tax != "" && tax != "0")}
                        />
                    </div>

                </div>
                <div style={{ margin: "5px", width: "100%", textAlign: "center" }}>
                    <button style={{ width: "100%", height: "50px", fontSize: "200%" }} type="submit" onClick={() => setView(0)} >Done</button>
                </div>

            </div>;
            break;
        case 3:
            componentToRender = <div className='tascInputsContainer'>
                <div className='tascInputContainer'>
                    <div className='tascInputFeild'>
                        <h2>Service Charge Percentage  </h2>
                        <input type='number' pattern="\d*(\.\d*)?" min="0" max="100"
                            style={{ width: "27%", height: "50px", fontSize: "160%", textAlign: "right", backgroundColor: (serviceChargeAmount != "" && serviceChargeAmount != "0") ? 'grey' : 'white', transition: 'background-color 0.5s ease' }}
                            value={serviceCharge}
                            onChange={event => setserviceCharge(event.target.value)}
                            disabled={(serviceChargeAmount != "" && serviceChargeAmount != "0")}
                        />
                    </div>

                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'spaceBetween', width: '60%' }}>
                    <hr style={{ width: '40%', borderColor: 'grey', borderWidth: '1px', borderTop: "none" }} />
                    <p>OR</p>
                    <hr style={{ width: '40%', borderColor: 'grey', borderWidth: '1px', borderTop: "none" }} />
                </div>
                <div className='tascInputContainer'>
                    <div className='tascInputFeild'>
                        <h2>Service Charge Amount  </h2>
                        <input type='number' pattern="\d*(\.\d*)?" min="0" 
                            inputMode='decimal'
                            style={{ width: "27%", height: "50px", fontSize: "160%", textAlign: "right", backgroundColor: (serviceCharge != "" && serviceCharge != "0") ? 'grey' : 'white', transition: 'background-color 0.5s ease' }}
                            value={serviceChargeAmount}
                            onChange={event => setserviceChargeAmount(event.target.value)}
                            disabled={(serviceCharge != "" && serviceCharge != "0")}
                        />
                    </div>

                </div>
                <div style={{ margin: "5px", width: "100%", textAlign: "center" }}>
                    <button style={{ width: "100%", height: "50px", fontSize: "200%" }} type="submit" onClick={() => setView(0)} >Done</button>
                </div>
            </div>;

            break;
        case 4:
            componentToRender = <div style={{ display: "flex", width: "85%", justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'column', height: "293px", marginRight: "auto", marginLeft: "auto" }}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: 'center' }}>
                    <h2>Discount Percentage</h2>
                    <input style={{ width: "35%", height: "50px", fontSize: "160%" }} type='number' inputMode='decimal' pattern="\d*(\.\d*)?" min="0" max="100" value={discount} onChange={handleDiscountInputChange} />
                </div>
                <div style={{ margin: "5px", width: "100%", textAlign: "center" }}>
                    <button style={{ width: "100%", height: "50px", fontSize: "200%" }} type="submit" onClick={() => setView(0)} >Done</button>
                </div>
            </div>;
            break;
        default:
            componentToRender = <div className='fourControlButtons'>
                <button className='billItemControlButtonQuater' type="button" onClick={() => setView(1)}>
                    Add
                </button>
                <button className='billItemControlButtonQuater' type="button" onClick={() => setView(2)}>
                    Tax
                </button>
                <button className='billItemControlButtonQuater' type="button" onClick={() => setView(3)}>
                    Service Charge
                </button>
                <button className='billItemControlButtonQuater' type="button" onClick={() => setView(4)}>
                    Discount
                </button>
            </div>;
    }

    return (
        <div>
            {componentToRender}
        </div>
    );
}
export default EditSection;