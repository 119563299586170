import React, { useState, useRef, useEffect } from 'react';
import './style.css';
import Switch from 'react-switch';
import QRCodeStyling from 'qr-code-styling';
import { WhatsappShareButton, WhatsappIcon, FacebookMessengerShareButton, FacebookMessengerIcon, LinkedinShareButton, LinkedinIcon, ViberShareButton, ViberIcon } from 'react-share';



export default function ShareGroupBox({ handleCloseShareGroup, hasCreatedGroup, setHasCreatedGroup }) {
  const [selectedIndex, setSelectedIndex] = useState(-1);
  let shareLink = 'https://web.dinedivide.app/group/' + window.location.href.split('/').pop();
  const qrCodeRef = useRef(null);
  const qrCodeParentRef = useRef(null);

  const [calcQRWidth, setCalcQRWidth] = useState("80%")

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    // Remove any existing QR code elements from qrCodeParentRef
    while (qrCodeParentRef.current.firstChild) {
      qrCodeParentRef.current.removeChild(qrCodeParentRef.current.firstChild);
    }
  
    let factor = 0.9; // default value
  
    // Adjust factor based on screen/viewport height
    if (window.innerHeight < 550) {
      factor = 0.6;
    } else if (window.innerHeight < 600) {
      factor = 0.7;
    }else if (window.innerHeight < 650) {
      factor = 0.8;
    }
  
    const width = qrCodeParentRef.current.clientWidth * factor;
    const height = qrCodeParentRef.current.clientWidth * factor;
  
    const qrCode = new QRCodeStyling({
      width: width,
      height: height,
      data: shareLink,
      dotsOptions: { color: '#1A1C33', type: 'rounded' },
      cornersSquareOptions: { type: 'extra-rounded' },
      backgroundOptions: { color: '#ffffff' }
    });
    qrCode.append(qrCodeParentRef.current);
    setVisible(true);
    if (window.innerHeight < 550) {
      setCalcQRWidth("55%")
    } else if (window.innerHeight < 600) {
      setCalcQRWidth("63%")
    }else if (window.innerHeight < 650) {
      setCalcQRWidth("73%")
    }
    return () => {
      setVisible(false);
    };

    
  }, [shareLink]);
  


  const quote = "Join my group and let's divide our dining";

  return (
    <div className={`shareGroupBox ${visible ? 'visible' : ''}`}>
      <div className='shareGroupTitle'>
        <div style={{ width: '10%' }}></div>
        <div
          style={{
            width: '80%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <h1 className="shareGroupTitleText">Share</h1>
        </div>
        <div className='closeButtonDiv' style={{ width: '10%' }}>
          <button onClick={() => { handleCloseShareGroup(false); setHasCreatedGroup(false) }} className='closeButton'>
            <img className='closeButtonImage' src={require('../../assets/close.png')} />
          </button>
        </div>
      </div>

      <div style={{ marginTop: '10px', width: '80%' }} className='div-ForLine'></div>

      <div style={{ textAlign: "center" }} className='shareRoomContent'>
        <div style={{ heigth: "10%", paddingTop: "5%" }}>
          <h1 style={{ margin: "0" }} className="shareGroupIDText">Group ID : {shareLink.substr(shareLink.length - 6)}</h1>
        </div>
        <div className='qrCodeParent' ref={qrCodeParentRef} style={{width : calcQRWidth}}>
          <div className="qrCode" ref={qrCodeRef}></div>
        </div>
        <div style={{ heigth: "20%" }}>
          <h3 style={{ margin: "0px" }} className="shareGroupText">Allow others to scan the QR Code or share on social media so others can join your group </h3>
        </div>
        <div
          className='shareButtons'
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            overflowX: 'scroll',
            height: '16%'
          }}
        >
          <WhatsappShareButton className='socialMedia' url={shareLink} title={quote}>
            <WhatsappIcon className='socialMediaIcon' round />
          </WhatsappShareButton>
          <FacebookMessengerShareButton className='socialMedia' url={shareLink} appId="YOUR_APP_ID">
            <FacebookMessengerIcon className='socialMediaIcon' round />
          </FacebookMessengerShareButton>
          <LinkedinShareButton className='socialMedia' url={shareLink} title={quote}>
            <LinkedinIcon className='socialMediaIcon' round />
          </LinkedinShareButton>
          <ViberShareButton className='socialMedia' url={shareLink} title={quote}>
            <ViberIcon className='socialMediaIcon' round />
          </ViberShareButton>
        </div>
        <div style={{ height: '14%' }}>
          <button style={{ width: "100%", height: "80%", fontSize: "200%", borderRadius: "35px" }} className='shareGroupButton' onClick={() => { handleCloseShareGroup(false); setHasCreatedGroup(false) }}>Done</button>
        </div>
      </div>
    </div>
  );
}
